import React from 'react';
import RowsInfo from "./RowsInfo";
import PagesLinks from "./PagesLinks";

export default function Pagination({ pagination }) {
    return (
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
                <a 
                    href={pagination.prev_page_url || "#"}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                > Previous </a>
                <a 
                    href={pagination.next_page_url || "#"}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                > Next </a>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <RowsInfo from={pagination.from} to={pagination.to} total={pagination.total}/>
                <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <PagesLinks links={pagination.links}/>
                    </nav>
                </div>
            </div>
        </div>
    )
}
