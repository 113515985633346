import React from 'react'
import MainContentLayout from "../Layouts/Layout";
import {Head} from "@inertiajs/inertia-react";
import FlashMessage from "../Components/FlashMessage/FlashMessage";

export default function Home() {
    return (
        <main>
            <FlashMessage/>
            <Head title={"Home"}/>
            <h1 className={"text-2xl text-center"}>Home</h1>
        </main>
    )
}

Home.layout = page => (
    <MainContentLayout children={page}/>
)
