import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

export const LogRow = ({ ignoreCell, logData, isSelected, selectLog }) => {
    const [ selectedStyle, setSelectedStyle ] = useState("")

    let siblingStyle = "";

    const isTimeRelated = (key) => {
        return key === 'updated_at'
        || key === 'created_at'
        || key === 'log_created_at'
        || key === 'send_time'
    }

    const logLevelStyle = (logLevel) => {
        let style = "text-white font-medium pl-4"
        style += {
            INFO: " bg-blue-600",
            WARN: " bg-yellow-400",
            ERROR: " bg-red-500",
        }[logLevel]

        return style;
    }

    // handling styling on selected log change
    useEffect(() => {
        function isSelectedChanged() {
            let baseStyle = "whitespace-nowrap rounded-lg font-bold py-2 px-4 cursor-pointer border ";
            baseStyle += isSelected? "bg-blue-600 text-white": "";

            setSelectedStyle(baseStyle)
        }
        isSelectedChanged()
    }, [isSelected]);

    return (
        <tr className='border-b'>
        {
            Object.keys(logData).reduce((rowList, key) => {
                if(ignoreCell.includes(key)) {
                    return rowList;
                }

                let value = key === 'data'? JSON.stringify(logData[key]): logData[key];
                let specificStyle = "text-slate-500 pr-8 whitespace-nowrap " + siblingStyle;

                siblingStyle = "";

                if(isTimeRelated(key)) {
                    value = value.includes("Z")? dayjs(value): dayjs(Number(value));

                    if(key === 'log_created_at') {
                        value = value.subtract(2, 'hours')
                    }

                    value = value.format("YYYY-MM-DD HH:mm:ss");
                }

                if(key === 'level') {
                    specificStyle = logLevelStyle(value);
                    siblingStyle = " pl-6";
                }

                specificStyle+=" " + (isSelected ? "bg-blue-100": "");

                rowList.push(
                    <td
                        key={`${logData.id}-${key}`}
                        className={`${specificStyle} py-2 first:px-6`}
                        onClick={() => selectLog(isSelected? {}: logData)}
                    >
                        {
                            key === 'data'? ( ""
                                // <button
                                //     type="button"
                                //     className={`${selectedStyle}`}
                                //     onClick={() => selectLog(isSelected? {}: logData)}
                                // >
                                //     {isSelected? "Close log data": "Open log data"}
                                // </button>
                            ): value
                        }
                    </td>
                )

                return rowList
            }, [])
        }
        </tr>
    )
}
