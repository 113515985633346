import {Fragment} from 'react'
import {Disclosure, Menu, Transition} from '@headlessui/react'
import {MenuIcon, XIcon} from '@heroicons/react/outline'
import {usePage, Link} from '@inertiajs/inertia-react'
import "./NavbarStyle.css";

const navigation = [
    { name: 'Users', href: '/users', current: false },
    { name: 'Documents', href: '/documents', current: false },
    {
        name: 'Helpers',
        dropdown: [
            { name: 'Resend invoice', href: '/helpers/resend-invoices', current: false },
            { name: 'Create invoice from logs', href: '/helpers/create-invoice-from-logs', current: false },
            { name: 'Regenerate reports', href: '/helpers/regenerate-reports', current: false },
            { name: 'MailApp users', href: '/helpers/mail-app-users', current: false },
            { name: 'SmsApp users', href: '/helpers/sms-app-users', current: false },
            { name: 'PdfApp users', href: '/helpers/pdf-app-users', current: false },
            { name: 'BffProxy users', href: '/helpers/bff-proxy-users', current: false },
            { name: 'Create tenant', href: '/helpers/create-tenant', current: false },
        ]
    },
    {
        name: 'Logs',
        dropdown: [
            { name: 'Logs', href: '/logs', current: false },
            { name: 'Log grouping', href: '/log-grouping', current: false },
        ]
    },
    { name: 'Invoice tracking', href: '/invoice-tracking', current: false },
    { name: 'Tenant', href: '/tenant', current: false },
    { name: 'Application monitoring', href: '/app-monitor', current: false },
]

export default function Nav() {
    const { auth } = usePage().props;
    const selectedStyle = "bg-gray-900 text-white";
    const notSelectedStyle = "text-gray-300 hover:bg-gray-700 hover:text-white', '";
    const userGroups = auth.user.groups.map(group => group.name);

    const canAccessRoute = (route) => {
        // which roles can access a route
        const routeRoleRequirement = {
            'Users': ['admin'],
            'Documents': '*',
            'Resend invoice': ['admin','developer'],
            'MailApp users': ['admin','developer'],
            'SmsApp users': ['admin','developer'],
            'PdfApp users': ['admin','developer'],
            'BffProxy users': ['admin','developer'],
            'Create tenant': ['admin','developer'],
            'Create invoice from logs': ['admin','developer'],
            'Regenerate reports': ['admin','developer'],
            'Logs': ['admin','developer'],
            'Log grouping': ['admin','developer'],
            'Invoice tracking': ['admin','developer'],
            'Tenant': '*',
            'Application monitoring': ['admin','developer'],
        }[route];

        if (routeRoleRequirement === '*') {
            return true;
        }

        for(let role of userGroups) {
            if(routeRoleRequirement.includes(role)) {
                return true;
            }
        }

        return false;
    }

    const getSingleNavItem = (routeItem) => {
        if(!canAccessRoute(routeItem.name)) {
            return null;
        }

        return (
            <Link
                key={routeItem.name}
                href={routeItem.href}
                className={`${routeItem.current ? selectedStyle: notSelectedStyle} px-3 py-2 rounded-md text-sm font-medium`}
                aria-current={routeItem.current ? 'page' : undefined}
            >
                {routeItem.name}
            </Link>
        );
    }

    const getDropdownNavItem = ({ name, dropdown }) => {
        const dropdownContent = dropdown.reduce((content, curItem) => {
            const dropdownItem = getSingleNavItem(curItem)

            if(dropdownItem) {
                content.push(dropdownItem);
            }

            return content;
        }, []);

        if(!dropdownContent.length) {
            return null;
        }

        return (
            <div key={`${name}_dropdown`} className="dropdown">
                <button
                    className={`${notSelectedStyle} px-3 py-2 rounded-md text-sm font-medium`}
                >
                    {name}
                </button>
                <div
                    className="dropdown-content flex-col absolute z-50 rounded-lg bg-gray-600 drop-shadow-md p-4"
                >
                    { dropdownContent }
                </div>
            </div>
        )
    }

    const getNavbarItems = () => {
        return navigation.reduce((navbarItems, curRoute) => {
            const isDropdown = !!curRoute.dropdown;
            const newNavItem = isDropdown? getDropdownNavItem(curRoute): getSingleNavItem(curRoute);

            if(newNavItem) {
                navbarItems.push(newNavItem);
            }

            return navbarItems;
        }, [])
    }

    return (
        <Disclosure as="nav" className="bg-gray-800">
            {({open}) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                                {/* Mobile menu button*/}
                                <Disclosure.Button
                                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true"/>
                                    ) : (
                                        <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                                    )}
                                </Disclosure.Button>
                            </div>
                            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                                <div className="flex-shrink-0 flex items-center">
                                    <h1 className={"text-2xl font-bold text-white font-sans"}>
                                        <Link href={"/"}>SuperIS</Link>
                                    </h1>
                                </div>
                                <div className="hidden sm:block sm:ml-6">
                                    <div className="flex space-x-4 relative">
                                        {
                                            getNavbarItems()
                                        }
                                    </div>
                                </div>
                            </div>
                            <div
                                className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button
                                            className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                            <span className="sr-only">Open user menu</span>
                                            <img className={"h-8 w-8 rounded-full"}
                                                src={auth.user.avatar ?? '/images/alternative-profile-photo.jpeg'}
                                                alt=""/>
                                        </Menu.Button>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items
                                            className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <Menu.Item>
                                                {({active}) => (
                                                    <Link
                                                        href={"/logout"}
                                                        className={`(${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                                                        method="post"
                                                    >
                                                        Sign out
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className={`${item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'} block px-3 py-2 rounded-md text-base font-medium`}
                                    aria-current={item.current ? 'page' : undefined}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}
