import React from 'react'
import Previous from "./Previous";
import Next from "./Next";
import PageLink from "./PageLink";

export default function PagesLinks({links}) {

    return (
        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            {links.map((link, i,{length}) => {
                if(i===0){
                    return <Previous url={link.url} key={i}/>
                }
                if(i + 1 === length){
                    return <Next url={link.url} key={i} />
                }
                return <PageLink link={link} key={i} />
            })}
        </nav>
    )
}
