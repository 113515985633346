import React, { useState, useEffect } from 'react';
import { Inertia } from '@inertiajs/inertia';
import dayjs from 'dayjs';
import { FaChevronRight } from 'react-icons/fa';
import { IoIosClose } from 'react-icons/io';
import { DisplayJSON } from "../DisplayJSON";

export function TenantResources({ tenantData, resourceList, item }) {
    const [ tenant, setTenant ] = useState(null);
    const [ selectedResource, setSelectedResource ] = useState(null);
    const [ selectedItem, setSelectedItem ] = useState(null);
    const [ resources, setResources ] = useState({});
    const resourceNames = [ "poas", "items", "clients", "cash-registers", "payment-methods", "documents" ]

    const getResourceName = (resource) => {
        const nameAttributes = ['name', 'code', 'document_number'];
        const foundAttribute = nameAttributes.find(attr => !!resource[attr]) || 'id';

        return resource[foundAttribute];
    }

    const gotoPage = (selectedPageUrl) => {
        if(!selectedPageUrl) {
            return;
        }
        const pageNum = selectedPageUrl.match(/page=(\d{1,2})/)[1];

        Inertia.get(
            `/tenant/${tenant.name}`,
            {
                tenantId: tenant.id,
                resource: selectedResource,
                page: pageNum,
            },
            {
                preserveState: true,
                preserveScroll: true,
                only: ['resources']
            }
        );
    }

    const fetchResourceDetails = (itemToFetch) => {
        if(!itemToFetch) {
            return;
        }

        // because only documents have extra data that we need to fetch
        if(selectedResource == "documents") {
            Inertia.get(
                `/tenant/${tenant.name}`,
                {
                    tenantId: tenant.id,
                    resourceId: `${selectedResource}_${itemToFetch.id}`,
                },
                {
                    preserveState: true,
                    preserveScroll: true,
                    only: ['resourceDetail']
                }
            );
            return;
        }

        setSelectedItem(itemToFetch);
    }

    const fetchResourceList = (resourceName) => {
        if(!resourceName) {
            return;
        }

        document.getElementById("resourceSearch").value = "";
        setSelectedResource(resourceName);
        setSelectedItem({});

        Inertia.get(
            `/tenant/${tenant.name}`,
            {
                tenantId: tenant.id,
                resource: resourceName,
            },
            {
                preserveState: true,
                preserveScroll: true,
                only: ['resources']
            }
        );
    }

    const getPagination = () => {
        const resource = resources[selectedResource];
        if(!resource || !resource.meta || resource.meta.last_page == 1) {
            return null;
        }

        const paginationList = resources[selectedResource].meta.links.map((link, index) => {
            let label = link.label.includes('Previous')? 'Previous' : link.label;
            label = link.label.includes('Next')? 'Next' : label;

            return (
                <button
                    key={link.label + "_" + index}
                    type="button"
                    className={`${link.active? 'font-medium text-blue-600 underline': ''}`}
                    onClick={() => gotoPage(link.url)}
                    disabled={!link.url}
                >
                    {label}
                </button>
            )
        })



        return ( <>
            { paginationList[0] }
            <div className='flex gap-x-4'>
                { paginationList.slice(1,-1) }
            </div>
            { paginationList[paginationList.length - 1] }
        </>);
    }

    const searchResources = (event) => {
        event.preventDefault();
        const searchTerm = event.target.resourceSearch.value;
        if(!selectedResource) {
            return;
        }
        if(!searchTerm) {
            fetchResourceList(selectedResource);
            return;
        }

        Inertia.get(
            `/tenant/${tenant.name}`,
            {
                tenantId: tenant.id,
                resource: selectedResource,
                search: searchTerm,
            },
            {
                preserveState: true,
                preserveScroll: true,
                only: ['resources']
            }
        );
    }

    const getResourceList = () => {
        const list = resources[selectedResource]?.data

        if(!selectedResource) {
            return []
        }
        else if(!list) {
            return ["Loading..."];
        }
        else if(list.length == 0) {
            return ["No results found."];
        }
        return list;
    }

    const getResourceItem = (curItem) => {
        const isSelected = selectedItem.id === curItem.id;

        if(selectedResource === "documents") {
            return (
                <button
                    key={curItem.id}
                    type="button"
                    className={`${isSelected? "bg-blue-100 border-blue-400 border-2": ""} w-full grid grid-cols-2 grid-rows-2 place-items-start py-2 px-4 mb-4 rounded-lg bg-slate-100 border drop-shadow-md`}
                    onClick={() => fetchResourceDetails(curItem)}
                >
                    <div> 
                        <span className='font-medium'> {curItem.document_number}</span>
                    </div>
                    <div> 
                        <span>DATE: </span>
                        <span className='font-medium'> { dayjs(curItem.document_time).format("DD.MM.YYYY HH:mm:ss") }</span> 
                    </div>
                    <div> 
                        <span>TYPE: </span>
                        <span className='font-medium'> { curItem.type } </span>
                    </div>
                    <div> 
                        <span>TOTAL: </span>
                        <span className='font-medium'> { curItem.retail_total } </span>
                    </div>
                </button>
            )
        }

        const itemName = getResourceName(curItem);
        return (
            <button
                key={curItem.id}
                type="button"
                className='w-full flex justify-between items-center mb-2'
                onClick={() => fetchResourceDetails(curItem)}
            >
                <h2 className={`${isSelected? 'font-medium text-blue-600': ''}`}>{itemName}</h2>
                {
                    isSelected && (
                        <FaChevronRight className='text-blue-600'/>
                    )
                }
            </button>
        )
    }

    useEffect(() => {
        function setStates() {
            if(Object.keys(tenantData).length > 0) {
                setTenant(tenantData);
            }
            if(Object.keys(item).length > 0) {
                setSelectedItem(item)
            }
            if(Object.keys(resourceList).length > 0) {
                setResources(resourceList);
            }
        }
        setStates();
    }, [tenantData, resourceList, item]);

    return (
        <>
            <div
                className='flex gap-x-4 items-start'
            >
                <div
                    className='w-2/12 min-h-[16.25rem] flex flex-col items-start gap-y-4 p-4 rounded-lg bg-slate-50 border drop-shadow-md'
                >
                    {
                        resourceNames.map(resourceName => {
                            const isSelected = selectedResource === resourceName;
                            const displayName = resourceName.toUpperCase();

                            return (
                                <button
                                    key={resourceName}
                                    type="button"
                                    className='w-full flex items-center justify-between'
                                    onClick={() => fetchResourceList(resourceName)}
                                >
                                    <h2
                                        className={`${isSelected? 'font-medium text-blue-600': ''}`}
                                    >
                                        {displayName}
                                    </h2>
                                    {
                                        isSelected && (
                                            <FaChevronRight className='text-blue-600'/>
                                        )
                                    }
                                </button>
                            )
                        })
                    }
                </div>

                <div
                    className='w-4/12 min-h-[16.25rem] flex flex-col items-start gap-y-2 p-4 rounded-lg bg-slate-50 border drop-shadow-md'
                >

                    {
                        <form
                            style={{display: `${selectedResource? '': 'none'}`}}
                            className='w-full flex justify-around gap-x-4 mb-4'
                            onSubmit={(event) => searchResources(event)}
                        >
                            <div className='w-full bg-white flex border rounded-lg'>
                                <input
                                    id="resourceSearch"
                                    type="text"
                                    className='rounded-lg outline-none flex-1 py-1 px-2'
                                    placeholder="Search..."
                                    defaultValue={""}
                                />
                                <button
                                    type="button"
                                    className='pr-2'
                                    onClick={() => {
                                        document.getElementById("resourceSearch").value = "";
                                        fetchResourceList(selectedResource);
                                    }}
                                >
                                    <IoIosClose className='text-slate-400 text-2xl'/>
                                </button>
                            </div>
                        </form>
                    }

                    <div className='w-full flex-1'>
                        {
                            getResourceList().map(curItem => {
                                if(typeof curItem === 'string') {
                                    return (
                                        <div key="not_found">
                                            <h2 className='text-center text-gray-500'>{curItem}</h2>
                                        </div>
                                    )
                                }

                                return getResourceItem(curItem);
                            })
                        }
                    </div>
                    <div
                        className='w-full flex justify-around gap-x-4'
                    >
                        { getPagination() }
                    </div>
                </div>

                <div
                    className='w-6/12 min-h-[16.25rem] flex flex-col gap-y-2 p-4 rounded-lg bg-slate-50 border drop-shadow-md overflow-auto'
                >
                    <DisplayJSON
                        id="resource-details"
                        jsonData={selectedItem}
                        onKeyClick={(key, value) => {
                            navigator.clipboard.writeText(`${key}: ${value}`);
                        }}
                    />
                </div>
            </div>
        </>
    )
}
