import React from 'react'

export default function Auth({children}) {
    return (
        <main>
            <div className={"font-sans text-gray-900 antialiased"}>
                <div className={"min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100"}>
                    <div
                        className={"w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg"}>
                        <article>{children}</article>
                    </div>
                </div>
            </div>
        </main>
    )
}
