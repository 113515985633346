import React from 'react'

export default function RowsInfo({from,to,total}) {
    return (
    <div>
        <p className="text-sm text-gray-700 mr-2">
            Showing&nbsp;
            <span className="font-medium">{from}</span>
            &nbsp;to&nbsp;
            <span className="font-medium">{to}</span>
            &nbsp;of&nbsp;
            <span className="font-medium">{total}</span>
            &nbsp;results
        </p>
    </div>
    )
}
