import React, {useState} from 'react'
import {Inertia} from "@inertiajs/inertia";
import {usePage} from '@inertiajs/inertia-react'


export default function SearchInput({filters}) {
    const [searchTerm = '', setSearchTerm] = useState(filters);

    const url = usePage().url;

    let searchHandler = (searchTerm) => {

        let lowerCaseSearchTerm = searchTerm.toLowerCase();
        setSearchTerm(lowerCaseSearchTerm);

        runSearch(lowerCaseSearchTerm);
    };

    function runSearch(lowerCaseSearchTerm) {
        Inertia.get(url.replace(/\?.*$/g,""), {q: lowerCaseSearchTerm}, {preserveState: true});
    }

    return (
        <input onChange={(e) => searchHandler(e.target.value)} value={searchTerm} type={"text"}
               placeholder={"Search..."} className={"border mr-8 px-2 rounded-lg"}/>
    )
}
