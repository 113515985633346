import React from 'react'
import MainContentLayout from "../../Layouts/Layout";
import {Head, Link, usePage} from '@inertiajs/inertia-react'
import Pagination from "../../Components/Pagination/Pagination";
import SearchInput from "../../Components/SearchInput/SearchInput";
import FlashMessage from "../../Components/FlashMessage/FlashMessage";

export default function Index({documents, filters}) {
    const {auth} = usePage().props

    return (
        <main>
            <Head title={"Documents"}/>

            <div className={"flex relative justify-between mb-6"}>
                <div className={"flex items-center"}>
                    <h1 className={"text-2xl"}>Documents</h1>
                    <Link href={"/documents/create"} className={"text-blue-500 text-sm ml-3"}>New Document</Link>
                    <FlashMessage/>
                </div>

                <SearchInput filters={filters}/>
            </div>
            <div className={"flex flex-col"}>
                <div className={"-m2 overflow-x-auto sm:-mx6 lg:mx-8"}>
                    <div className={"shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"}>
                        <table className={"min-w-full divide-y divide-gray-200"}>
                            <thead className={"bg-gray-50"}>
                            <tr>
                                <th scope={"col"} className={"px-6 py-3 text-left text-xs font-medium text-gray-500" +
                                    " uppercase tracking-winder"}>
                                    Name/Description
                                </th>
                                <th scope={"col"} className={"px-6 py-3 text-left text-xs font-medium text-gray-500" +
                                    " uppercase tracking-winder"}>
                                    Files
                                </th>
                                <th scope={"col"} className={"px-6 py-3 text-left text-xs font-medium text-gray-500" +
                                    " whitespace-nowrap uppercase tracking-winder"}>
                                    Created By
                                </th>
                                <th scope={"col"} className={"relative px-6 py-3"}>
                                    <span className={"sr-only"}>Edit</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className={"bg-white divide-y divide-gray-200"}>
                            {documents.data.map(document => (
                                <tr key={document.id}>
                                    <td className={"max-w-xs truncate px-6 py-4 text-sm text-gray-700"}>
                                        <div className={"flex items-center"}>
                                            <div className={"ml-4"}>
                                                <div className={"text-sm font-medium text-gray-700"}>
                                                    {document.name}
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td className={"max-w-xs truncate px-6 py-4 text-sm text-gray-700"}>
                                        {document.files.map(file => (
                                            <a key={file.id} className={"text-blue-500 hover:underline"}
                                               href={`/files/${file.id}/download`}>
                                                {file.name}<br/>
                                            </a>
                                        ))}
                                    </td>
                                    <td className={"px-6 py-4 text-sm text-gray-700"}>
                                        {auth.user.id === document.user.id ? 'You' : document.user.name}
                                    </td>
                                    <td className={"px-6 py-4 whitespace-nowrap text-right text-sm font-medium"}>
                                        <Link href={`/documents/${document.id}/edit`} className={"text-indigo-600" +
                                            " hover:text-indigo-900"}>Edit</Link>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Paginator */}
            <div>
                <Pagination pagination={documents}/>
            </div>
        </main>
    )
}

Index.layout = page => (
    <MainContentLayout children={page}/>
)
