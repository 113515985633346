import React from 'react'
import MainContentLayout from "../../../Layouts/Layout";
import {Head, useForm, usePage} from '@inertiajs/inertia-react'
import {Inertia} from "@inertiajs/inertia";
import FlashMessage from "../../../Components/FlashMessage/FlashMessage";


export default function Index() {
    const {errors} = usePage().props
    const {data, setData} = useForm({
        tenant_id: null,
        cash_register_code: null,
        poa_code: null,
        month: null,
        year: null,
        _token: usePage().props._token,
    });

    const [load, setLoad] = React.useState(false);

    function handleSubmit(e) {
        e.preventDefault();

        Inertia.post('/helpers/regenerate-reports', data, {
            onStart: () => {
                setLoad(true);
            },
            onFinish: () => {
                setLoad(false);
            },
        })

    }

    return (
        <main>

            <Head title={"Regenerate reports"}/>

            <div className={"flex relative justify-between mb-6"}>
                <div className={"flex items-center"}>
                    <h1 className={"text-2xl"}>Regenerate reports</h1>
                    <FlashMessage/>
                </div>
            </div>

            <form onSubmit={handleSubmit} className={"max-w-md mx-auto mt-8"}>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Tenant ID
                    </label>
                    <input className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} type={"text"} name={"tenant_id"}
                           onChange={(e) =>
                               setData("tenant_id", e.target.value)
                           }
                    />
                    {errors.tenant_id && <div className={"text-red-700"}>{errors.tenant_id}</div>}
                </div>
                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Cash register code
                    </label>
                    <input className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} type={"text"} name={"cash_register_code"}
                           onChange={(e) =>
                               setData("cash_register_code", e.target.value)
                           }
                    />
                    {errors.cash_register_code && <div className={"text-red-700"}>{errors.cash_register_code}</div>}
                </div>
                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Poa code
                    </label>
                    <input className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} type={"text"} name={"poa_code"}
                           onChange={(e) =>
                               setData("poa_code", e.target.value)
                           }
                    />
                    {errors.poa_code && <div className={"text-red-700"}>{errors.poa_code}</div>}
                </div>
                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Month
                    </label>
                    <input className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} type={"text"} name={"month"}
                           onChange={(e) =>
                               setData("month", e.target.value)
                           }
                    />
                    {errors.month && <div className={"text-red-700"}>{errors.month}</div>}
                </div>
                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Year
                    </label>
                    <input className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} type={"text"} name={"year"}
                           onChange={(e) =>
                               setData("year", e.target.value)
                           }
                    />
                    {errors.year && <div className={"text-red-700"}>{errors.year}</div>}
                </div>

                <div className={"flex mb-6 float-right space-x-2"}>
                    <button type={"submit"}
                            className={"inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"}
                            disabled={load}>
                        Submit
                    </button>
                </div>

            </form>

        </main>
    )
}

Index.layout = page => (
    <MainContentLayout children={page}/>
)
