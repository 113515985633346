import React from 'react'
import { render } from 'react-dom'
import {createInertiaApp} from '@inertiajs/inertia-react'
import {InertiaProgress} from '@inertiajs/progress'

InertiaProgress.init({
    showSpinner: true,
})

createInertiaApp({
    title: title => `SuperIS - ${title}`,
    resolve: name => require(`./Pages/${name}`),
    setup({el, App, props}) {
        render(<App {...props} />, el)
    },
});
