import React, {useState} from 'react'
import '../../../css/app.css'
import {usePage} from "@inertiajs/inertia-react";

export default function FlashMessage() {
    const {flash} = usePage().props
    const [fadeProp, setFadeProp] = useState({
        hidden: true,
        fade: 'fade-out hidden flex p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200'
    })

    if (flash.message) {
        if (fadeProp.hidden === true) {
            setFadeProp({
                fade: 'fade-in flex p-4 mb-4 rounded-lg' + (flash.type === 'success' ? ' bg-green-100' +
                    ' dark:bg-green-200' : ' bg-red-100 dark:bg-red-200')
            })
        }
        setTimeout(() => {
            flash.message = null
            setFadeProp({
                fade: 'fade-out hidden flex p-4 mb-4',
                hidden: true,
            })
        }, flash.duration)
    }

    return (
        <div className={"absolute right-8"}>
            <div id="flashMessageId" className={fadeProp.fade}
                 role="alert">
                <svg className="flex-shrink-0 w-5 h-5 text-gray-600 dark:text-gray-600"
                     fill="currentColor"
                     viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                          clipRule="evenodd"></path>
                </svg>
                <div className="ml-3 text-sm font-medium text-gray-600 dark:text-gray-600">
                    {flash.message}
                </div>
            </div>
        </div>
    )
}
