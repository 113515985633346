import { Inertia } from "@inertiajs/inertia";
import { Head, usePage } from '@inertiajs/inertia-react';
import React, { useEffect, useState } from 'react';
import { IoIosClose, IoIosCopy } from 'react-icons/io';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

import { Divider } from "../../Components/Divider";
import { InvoiceTraceLog } from "../../Components/InvoiceTracking/InvoiceTraceLog";
import { InvoiceLogGroup } from "../../Components/InvoiceTracking/InvoiceLogGroup";
import MainContentLayout from "../../Layouts/Layout";
import { processLogs, groupLogs } from "../../utils/invoiceLogHelpers";

export default function InvoiceTracking({invoiceLogs}) {
    const [ searchParams, setSearchParams ] = useState({});
    const [ showApLogs, setShowApLogs ] = useState(true);
    const [ showDocLogs, setShowDocLogs ] = useState(true);
    const [ shouldGroupLogs, setShouldGroupLogs ] = useState(false);
    
    const containerStyle = "shadow overflow-hidden border-b border-gray-200 rounded-lg bg-slate-50";
    const chipStyle = "flex items-center gap-x-2 px-4 py-2 font-medium text-white rounded-lg"
    
    const { isInvoiceSaved, logList, duplicateLogs } = processLogs(invoiceLogs, showApLogs, showDocLogs)

    const validateParams = (params) => {
        const validators = {
            invoiceNumber: /((\w|\d){8})-(\d{1,})$/
        }

        return Object.keys(params).reduce((newParams, key) => {
            const value = params[key];
            const validator = validators[key]

            const matches = value.match(validator);
            const res =  matches? matches[0]: null
        
            if(res) {
                newParams[key] = res;
            }

            return newParams
        }, {})
    }

    const updateSearchParams = (key, value) => {
        const params = {...searchParams, [key]: value};

        if(value == null) {
            delete params[key];
            document.getElementById(key).value = "";
        }

        setSearchParams(params);
    }

    const filterResults = (event) => {
        event.preventDefault();

        const params = validateParams({...searchParams})
        Inertia.get("/invoice-tracking", params, {preserveState: true});
    }

    const displayLogs = () => {
        if(shouldGroupLogs) {
            const groupedLogs = groupLogs(logList)

            return Object.keys(groupedLogs).map((curGroupe, index) => {
                const logGroup = groupedLogs[curGroupe];
                return <InvoiceLogGroup key={index} index={index} groupName={curGroupe} logList={logGroup}/>
            })
        }
        
        return logList.map(log => {
            const key = `${log.id}-${Math.random()}`;
            return <InvoiceTraceLog key={key} logData={log}/>
        })
    }

    const goToInvoice = (event, goToInvoice) => {
        event.preventDefault();

        const isValidInvoice = (searchParams?.invoiceNumber || "").match(/((\w|\d){8})-(\d{1,})$/);

        if(!isValidInvoice) {
            return;
        }

        const nextInvoiceNumber = Number(isValidInvoice[3]) + goToInvoice;
        const fullInvoiceNumber = `${isValidInvoice[1]}-${nextInvoiceNumber}`;
        const newSearchParams = { invoiceNumber: fullInvoiceNumber }
        
        updateSearchParams("invoiceNumber", fullInvoiceNumber);
        document.getElementById("invoiceNumber").value = fullInvoiceNumber;

        Inertia.get("/invoice-tracking", newSearchParams, {preserveState: true});
    }

    const url = usePage().url;
    useEffect(() => {
        const initInvoiceNum = url.match(/((\w|\d){8})-(\d{1,})$/) || [""];
        document.getElementById("invoiceNumber").value = initInvoiceNum[0];
        updateSearchParams("invoiceNumber", initInvoiceNum[0]);
    }, [])

    return (
        <main>
            <Head title={"InvoiceTracking"}/>

            <form 
                onSubmit={filterResults}
                className="flex flex-col md:flex-row md:flex-wrap md:gap-x-2"
            >
                <button 
                    className={`${containerStyle} px-2 font-medium text-xl text-slate-600 `} 
                    onClick={(event) => goToInvoice(event, -1)}
                    type="button"
                >
                    <HiChevronLeft/>
                </button>

                <div className={`${containerStyle} flex md:w-[49%]`}>
                    <input
                        type="text"
                        id={`invoiceNumber`}
                        className='w-full rounded-lg outline-none p-2 bg-slate-50'
                        placeholder={`Invoice number...`}
                        onChange={(event) => updateSearchParams("invoiceNumber", event.target.value)}
                    />

                    <button
                        type="button"
                        className='pr-2'
                        onClick={() => updateSearchParams("invoiceNumber", null)}
                    >
                        <IoIosClose className='text-slate-400 text-2xl'/>
                    </button>
                </div>

                <button 
                    className={`${containerStyle} px-2 font-medium text-xl text-slate-600 `} 
                    onClick={(event) => goToInvoice(event, 1)}
                    type="button"
                >
                    <HiChevronRight/>
                </button>
            </form>

            <Divider margin="my-4"/>

            <div className="flex items-center gap-x-4">
                <label className="flex items-center gap-x-1">
                    AP LOGS: 
                    <input 
                        type="checkbox" 
                        checked={showApLogs} 
                        onChange={() => setShowApLogs(!showApLogs)}
                    />
                </label>
                <label className="flex items-center gap-x-1">
                    DOCUMENT LOGS: 
                    <input 
                        type="checkbox" 
                        checked={showDocLogs} 
                        onChange={() => setShowDocLogs(!showDocLogs)}
                    />
                </label>
                <label className="flex items-center gap-x-1">
                    GROUP LOGS: 
                    <input 
                        type="checkbox" 
                        checked={shouldGroupLogs} 
                        onChange={() => setShouldGroupLogs(!shouldGroupLogs)}
                    />
                </label>
            </div>

            {
                !logList.length? (
                    <div className="w-full flex items-center justify-center py-10"> 
                        <h1 className="text-lg font-medium">No logs found for given invoice</h1>
                    </div>
                ): (
                    <div>
                        <Divider margin="my-4"/>

                        <div className="w-full flex flex-wrap gap-x-4 gap-y-2">
                            <span className={`${isInvoiceSaved.taxCore? "bg-green-600": "bg-red-600"} ${chipStyle}`}>
                                TaxCore: {isInvoiceSaved.taxCore? "Saved": "Not saved"}
                            </span>

                            <span className={`${isInvoiceSaved.salesApp? "bg-green-600": "bg-red-600"} ${chipStyle}`}>
                                SalesApp: {isInvoiceSaved.salesApp? "Saved": "Not saved"}
                            </span>
                            
                            <span className={`${chipStyle} bg-blue-600`}>
                                Duplicate logs: {duplicateLogs? "Yes": "No"}
                            </span>
                            
                            <span className={`${chipStyle} bg-blue-600`}>
                                Tenant: {logList[0].tenant_id} 
                                <button onClick={() => navigator.clipboard.writeText(logList[0].tenant_id)}>
                                    <IoIosCopy className='text-lg hover:text-slate-200 active:text-slate-300'/>
                                </button> 
                            </span>
                            
                            <span className={`${chipStyle} bg-blue-600`}>
                                Device: {logList[0].device_id}
                                <button onClick={() => navigator.clipboard.writeText(logList[0].device_id)}>
                                    <IoIosCopy className='text-lg hover:text-slate-200 active:text-slate-300'/>
                                </button> 
                            </span>
                        </div>

                        <Divider margin="my-4"/>
                        {
                            displayLogs()
                        }
                    </div>
                )
            }
        </main>
    )
}

InvoiceTracking.layout = page => (
    <MainContentLayout children={page}/>
)
