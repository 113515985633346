import React from 'react';
import { Inertia } from "@inertiajs/inertia";
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

export const Pagination = ({ logs, searchParams }) => {
    //return (<></>)
    // const gotoPage = (selectedPageUrl) => {
    //     if(!selectedPageUrl) {
    //         return;
    //     }
    //
    //     const pageNum = selectedPageUrl.match(/page=(\d{1,})/)[1];
    //     searchParams = { ...searchParams, page: pageNum };
    //
    //     Inertia.get(url, searchParams, { preserveState: true });
    // }

    // if(!paginationData) {
    //     return <></>;
    // }

    // const paginationList = paginationData.links.map((link, index) => {
    //     let label = index === 0? <HiChevronLeft className='text-xl' /> : link.label;
    //     label = index === paginationData.links.length - 1? <HiChevronRight className='text-xl' /> : label;

    //     return (
    //         <button
    //             key={`${link.label}_${index}}`}
    //             type="button"
    //             className={`${link.active? 'font-bold text-white underline bg-blue-600 rounded-lg': 'font-medium text-gray-500'} px-3 py-2 `}
    //             onClick={() => gotoPage(link.url)}
    //             disabled={!link.url}
    //         >
    //             {label}
    //         </button>
    //     )
    // })



    return (
        <div className='flex'>
            <button
                type="button"
                className="font-medium text-blue-400 px-3 py-2"
                onClick={() => Inertia.get(logs.first_page_url, searchParams, { preserveState: true })}
                disabled={!logs.first_page_url}
            >
                &lt;&lt; Prva
            </button>

            <button
                type="button"
                className="font-medium text-blue-600 px-3 py-2"
                onClick={() => Inertia.get(logs.prev_page_url, searchParams, { preserveState: true })}
                disabled={!logs.prev_page_url}
            >
                &lt; Predhodna
            </button>

            <span className="font-large px-3 py-2">{logs.current_page}</span>

            <button
                type="button"
                className="font-medium text-blue-600 px-3 py-2"
                onClick={() => Inertia.get(logs.next_page_url, searchParams, { preserveState: true })}
                disabled={!logs.next_page_url}
            >
                Slijedeća &gt;
            </button>

            {/*<a href="#" className="p-10 text-blue-500">&lt;&lt; Prva</a>*/}
            {/*<a href="#" className="p-10 text-blue-700">&lt; Predhodna</a>*/}
            {/*<span>{logs.current_page}</span>*/}
            {/*<a href={logs.next_page_url} className="p-10 text-blue-700">Slijedeća &gt;</a>*/}
        </div>
    );
}
