import React, { useState } from "react";
import { InvoiceTraceLog } from "./InvoiceTraceLog"
import { Divider } from "../Divider"
import { HiChevronUp, HiChevronDown } from 'react-icons/hi';

export function InvoiceLogGroup({ index, groupName, logList }) {
    const [showContent, setShowContent] = useState(true)

    return (
        <>
            <button
                onClick={() => setShowContent(!showContent)}
                className="w-full flex justify-between items-center px-4 py-2 mt-4 mb-4 rounded-lg bg-slate-600"
            >
                <h2 className="text-white font-medium">{`${index + 1}. ${groupName}`}</h2>
                <span>
                    {
                        showContent? (
                            <HiChevronDown className='text-xl text-white hover:text-slate-200 active:text-slate-300'/>
                        ): (
                            <HiChevronUp className='text-xl text-white hover:text-slate-200 active:text-slate-300'/>
                        )
                    }
                </span> 
            </button>
            <div className={`${showContent? "": "max-h-0"} overflow-hidden px-2`}>
                {
                    logList.map(log => {
                        const key = `${log.id}-${Math.random()}`;
                        return <InvoiceTraceLog key={key} logData={log}/>
                    })
                }
            </div>
            <Divider />
        </>
    )
}
