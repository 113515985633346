import React, { useEffect } from "react";
import { deepParseJson } from "deep-parse-json";

export function DisplayJSON({ jsonData, onKeyClick, id }) {
    function syntaxHighlight(json) {
        const pre = document.getElementById(id);

        if (typeof json != 'string') {
            if(!json || Object.keys(json).length == 0) {
                pre.innerHTML = "";
                return;
            }
            
            json = JSON.stringify(deepParseJson(json), null, 2)
        }

        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        const regex = /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g

        let wasLastValueJson = false;

        const result = json.replace(regex, function (match) {
            let cls = 'text-purple-600 font-bold';
            let isKey = false;

            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    isKey = true;
                    cls = ' text-gray-500 font-thin italic'; // key
                    match = match.replace(/"/g, "");
                    match = match.replace(/:/, "");
                }
                else {
                    cls = 'text-green-600 font-medium'; // string
                }
            }
            else if (/true|false/.test(match)) {
                cls = 'text-blue-600 font-bold'; // bool
            }
            else if (/null/.test(match)) {
                cls = 'text-gray-400 italic'; //null
                match = "NULL"
            }

            if(isKey) {
                return `<button id="${match}" class="${cls} ${id}_json_key" style="cursor: default">${match}:</button>`
            }
            if(match.includes("https://") || match.includes("http://")) {
                return `<a href=${match} target="_blank" style="color: blue; font-weight: 700; font-size: 16px; text-decoration: underline">Open link</a>`
            }

            return `<span class="${cls}">${match}</span>`
        });

        pre.innerHTML = result;
        attachOnClickEvent();
    }

    function attachOnClickEvent() {
        if(!onKeyClick) {
            return;
        }

        const buttonList = document.getElementsByClassName(`${id}_json_key`);

        Array.from(buttonList).forEach(button => {
            let value = button.nextSibling.nextSibling.innerText;

            const isRootLevelKey = Object.keys(jsonData).includes(button.id);
            const isValueJson = jsonData[button.id] && typeof jsonData[button.id] === "object";

            if(!isRootLevelKey) {
                button.style.cursor = "initial";
                return;
            }
            if(isValueJson) {

            }

            value = value === "NULL" ? "null" : value;
            button.addEventListener("click", () => onKeyClick(button.id, value));
            button.style.cursor = "pointer";
            button.style.textDecoration = "underline";
        })
    }

    useEffect(() => {
        syntaxHighlight(jsonData);
    }, [jsonData])

    return (
        <pre id={id}></pre>
    )
}
