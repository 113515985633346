import React from 'react'

export const MonitorGroupCard = (params) => {
    return (
        <div className={`min-w-fit bg-white rounded-lg drop-shadow-lg flex flex-col flex-1 p-4`}>
            <div className='flex justify-center pb-2'>
                <h1 className="text-black text-xl font-bold"> { params.title } </h1>
            </div>
            <div className='flex flex-col flex-wrap gap-y-1'>
                { params.monitorList }
            </div>
        </div>
    )
} 