import React from 'react'
import MainContentLayout from "../../Layouts/Layout";
import {Head, useForm, usePage} from '@inertiajs/inertia-react'
import {Inertia} from "@inertiajs/inertia";
import FlashMessage from "../../Components/FlashMessage/FlashMessage";
import Select from "react-select";

export default function Edit({document, files, passwords, groups, users}) {
    const {errors} = usePage().props
    const {data, setData, progress} = useForm({
        name: document.name,
        description: document.description || "",
        groups: document.groups.map(group => group.id),
        users: [],
        passwords: [],
        _token: usePage().props._token,
    });

    const [load, setLoad] = React.useState(false);
    const [inputList, setInputList] = React.useState([]);

    function handleSubmit(e) {
        e.preventDefault();

        const sendData = {...data, passwords: inputList};
        setData("passwords", inputList);

        Inertia.put('/documents/' + document.id, sendData, {
            onStart: () => {
                setLoad(true);
            },
            onFinish: () => {
                setLoad(false);
            },
        })
    }

    const handleAddUser = (e) => {
        let selectedUsers = [];

        for (const value in e) {
            selectedUsers.push(e[value].value)
        }

        setData("users", selectedUsers)
    };

    function deleteUser(e) {
        e.preventDefault();
        Inertia.delete('/users/' + document.id + '/' + e.target.value + '/delete')
    }

    function handleBack(e) {
        e.preventDefault();
        Inertia.get('/documents');
    }

    function deleteFile(e) {
        e.preventDefault();
        Inertia.delete('/files/' + document.id + '/' + e.target.value + '/delete')
    }

    function addFile(e) {
        e.preventDefault();
        Inertia.post('/files/' + document.id + '/add', e.target.files)
        e.target.value = null;
    }

    function deletePassword(e) {
        e.preventDefault();

        Inertia.delete('/passwords/' + document.id + '/' + e.target.value + '/delete')
    }

    function handleDelete(e) {
        e.preventDefault();
        Inertia.delete('/documents/' + document.id)
    }

    const handleChecked = (event) => {
        const group_id = event.target.value;
        const checked = event.target.checked;

        if (checked) {
            setData("groups", [...data.groups, Number(group_id)]);
        } else {
            setData("groups", data.groups.filter(id => id != group_id));
        }
    };

    const handleInputChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);

    }

    const handleRemovePassword = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    }

    const handleAddPasswordClick = (e) => {
        e.preventDefault();
        setInputList([...inputList, {
            username: null,
            password: null,
            url: null,
        }]);
    }

    return (
        <main>

            <Head title={"Edit Password"}/>

            <div className={"flex relative justify-between mb-6"}>
                <div className={"flex items-center"}>
                    <h1 className={"text-2xl"}>Edit Document</h1>
                    <FlashMessage/>
                </div>
            </div>

            <form onSubmit={handleSubmit} className={"max-w-md mx-auto mt-8"}>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Name
                    </label>
                    <input className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} type={"text"} name={"name"}
                           onChange={(e) =>
                               setData("name", e.target.value)
                           }
                           value={data.name}/>
                    {errors.name && <div className={"text-red-700"}>{errors.name}</div>}
                </div>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Description
                    </label>
                    <textarea rows={4} className={"shadow appearance-none border rounded w-full py-2" +
                        " px-3" +
                        " text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} name={"description"}
                              onChange={(e) =>
                                  setData("description", e.target.value)
                              }
                              value={data.description}/>
                    {errors.description && <div className={"text-red-700"}>{errors.description}</div>}
                </div>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Users
                    </label>
                    <Select isMulti
                            options={users}
                            onChange={handleAddUser}
                            className={"mb-2"}
                    />
                    {document.users.map(user => (
                        <table className={"divide-y divide-gray-200"} key={user.id}>
                            <tbody>
                            <tr>
                                <td className={"text-sm text-gray-700"}>
                                    {user.name}
                                </td>
                                <td>
                                    <button type={"button"} onClick={deleteUser} value={user.id} name={user.id}
                                            className={"text-sm" +
                                                " ml-2 text-red-700 hover:underline"}>
                                        X
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    ))}
                </div>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Groups
                    </label>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1">
                        {
                            groups.map((group) => (
                                <div className="form-check" key={group.id}>
                                    <label className="form-check-label inline-block text-gray-700" htmlFor={group.id}>
                                        <input
                                            className="form-check-input h-4 w-4 border border-gray-700 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                            type="checkbox"
                                            id={group.id}
                                            onChange={handleChecked}
                                            value={group.id}
                                            checked={data.groups.includes(group.id)}
                                        />
                                        {group.name}
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                    {errors.groups && <div className={"text-red-700"}>{errors.groups}</div>}
                </div>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Files
                    </label>
                    <input className={"mb-2"} type="file" name={"file"} multiple onChange={addFile}/>
                    {progress && (
                        <progress value={progress.percentage} max="100">
                            {progress.percentage}%
                        </progress>
                    )}
                    <table className={"divide-y divide-gray-200"}>
                        <tbody>
                        {files.map(file => (
                            <tr key={file.id}>
                                <td>
                                    <a className={"text-sm text-gray-700 hover:underline"}
                                       href={`/files/${file.id}/download`}>
                                        {file.name}
                                    </a>
                                </td>
                                <td>
                                    <button type={"button"} onClick={deleteFile} value={file.id} name={file.id}
                                            className={"text-sm" +
                                                " ml-2 text-red-700 hover:underline"}>
                                        X
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Passwords
                    </label>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="overflow-hidden">
                                    <table className="min-w-full">
                                        <thead className="border-b">
                                        <tr>
                                            <th scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                Username
                                            </th>
                                            <th scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                Password
                                            </th>
                                            <th scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                URL
                                            </th>
                                            <th scope="col"
                                                className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                                                Options
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {passwords.map(password => (
                                            <tr className="border-b" key={password.id}>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {password.username}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {password.password}
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    {password.url ?
                                                        <a className={"text-sm text-gray-700 hover:underline"}
                                                           href={password.url}>LINK</a> : <p>-</p>
                                                    }
                                                </td>
                                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                    <button type={"button"} onClick={deletePassword} value={password.id}
                                                            name={password.id} className={"text-sm ml-2 text-red-700" +
                                                        " hover:underline"}>
                                                        X
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {inputList.map((x, i) => {
                        return (
                            <div className="mt-2 space-y-2" key={"pass_div_" + i}>
                                <input name="username" className={"shadow appearance-none border rounded" +
                                    " w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" +
                                    " focus:shadow-outline border border-solid border-gray-300 p-2 w-full"}
                                       type={"text"} placeholder="Enter Username" key={"pass_username_" + i}
                                       onChange={e => handleInputChange(e, i)}/>
                                {errors[`passwords.${i}.username`] && <div className={"text-red-700 m-1"}>
                                    {errors[`passwords.${i}.username`]}</div>}
                                <input name="password" className={"shadow appearance-none border rounded" +
                                    " w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" +
                                    " focus:shadow-outline border border-solid border-gray-300 p-2 w-full"}
                                       type={"text"} placeholder="Enter Password" key={"password_" + i}
                                       onChange={e => handleInputChange(e, i)}/>
                                {errors[`passwords.${i}.password`] && <div className={"text-red-700 m-1"}>
                                    {errors[`passwords.${i}.password`]}</div>}
                                <input name="url" className={"shadow appearance-none border rounded" +
                                    " w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" +
                                    " focus:shadow-outline border border-solid border-gray-300 p-2 w-full"}
                                       type={"text"} placeholder="Enter URL" key={"pass_url_" + i}
                                       onChange={e => handleInputChange(e, i)}/>
                                {errors[`passwords.${i}.url`] && <div className={"text-red-700 m-1"}>
                                    {errors[`passwords.${i}.url`]}</div>}
                                <button className={"inline-block px-6 py-2.5 bg-red-600 text-white" +
                                    " font-medium" +
                                    " text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700" +
                                    " hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none" +
                                    " focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150" +
                                    " ease-in-out"} onClick={() => handleRemovePassword(i)}>Remove Password
                                </button>
                            </div>
                        );
                    })}
                    <div className={"my-2 space-y-2"}>
                        <button className={"inline-block px-6 py-2.5 bg-green-500 text-white" +
                            " font-medium text-xs leading-tight uppercase rounded shadow-md" +
                            " hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg" +
                            " focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg" +
                            " transition duration-150 ease-in-out"}
                                onClick={handleAddPasswordClick}>Add Password
                        </button>
                    </div>
                </div>
                <div className="flex mb-6 flow-root space-x-2">
                    <button onClick={handleDelete}
                            className={"float-left inline-block px-6 py-2.5 bg-red-600 text-white font-medium" +
                                " text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"}>
                        Delete
                    </button>
                    <button type={"submit"}
                            className={"float-right inline-block px-6 py-2.5 bg-blue-600 text-white font-medium" +
                                " text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"}
                            disabled={load}>
                        Submit
                    </button>
                    <button onClick={handleBack}
                            className={"float-right inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium" +
                                " text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"}>
                        Back
                    </button>
                </div>
            </form>

        </main>
    )
}

Edit.layout = page => (
    <MainContentLayout children={page}/>
)
