import {Inertia} from '@inertiajs/inertia'
import React from 'react'
import AuthLayout from '../Layouts/Auth'

export default function Login() {
    function handleClick(e) {
        e.preventDefault()
        Inertia.get('/auth/redirect')
    }

    return (
        <AuthLayout>
            <div className="text-center mb-3">
                <h6 className="text-gray-600 text-sm font-bold">
                    Sign in
                </h6>
            </div>
            <div className="text-center mb-3">

                <button onClick={handleClick}
                        className={"py-3 px-6 rounded-xl bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 active:bg-blue-200"}>
                    <div className={"flex justify-center"}>
                        <img className={"w-10"} src={'/images/google.svg'}/>
                        <span
                            className={"flex items-center justify-center block w-max font-medium tracking-wide" +
                                " text-sm mx-3 text-blue-600"}>
                                    with Google
                                </span>
                    </div>
                </button>
            </div>
        </AuthLayout>

    )
}
