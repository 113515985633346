import React from 'react'
import MainContentLayout from "../../../Layouts/Layout";
import {Head, Link} from '@inertiajs/inertia-react'
import FlashMessage from "../../../Components/FlashMessage/FlashMessage";
import {Inertia} from "@inertiajs/inertia";

export default function Index({users}) {

    const [load, setLoad] = React.useState(false);

    function deleteUser(e) {
        e.preventDefault();
        Inertia.delete('/helpers/bff-proxy-users/' + e.target.id + '/delete', {
            onStart: () => {
                setLoad(true);
            },
            onFinish: () => {
                setLoad(false);
            },
        })
    }

    function deleteUserToken(e) {
        e.preventDefault();
        Inertia.delete('/helpers/bff-proxy-users/' + e.target.id + '/token/delete', {
            onStart: () => {
                setLoad(true);
            },
            onFinish: () => {
                setLoad(false);
            },
        })
    }

    function checkToken() {
        let token = document.getElementById("check_token_field_id").value

        Inertia.get('/helpers/bff-proxy-users/token/check/' + token);

    }

    return (
        <main>
            <Head title={"BffProxy Users"}/>

            <div className={"flex relative justify-between mb-6"}>
                <div className={"flex items-center"}>
                    <h1 className={"text-2xl"}>BffProxy Users</h1>
                    <Link href={"/helpers/bff-proxy-users/create"} className={"text-blue-500 text-sm ml-3"}>New
                        user</Link>
                    <FlashMessage/>
                </div>
                <div>
                    <input type={"text"} placeholder={"Check token..."} name={"token"} id={"check_token_field_id"}
                           className={"border text-sm mr-2 px-3 py-1.5 rounded-md min-w-[30rem]"}/>
                    <button type={"button"} onClick={checkToken}
                            className="inline-block px-3 py-1.5 mr-8 bg-violet-600
        text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-violet-700 hover:shadow-lg
        focus:bg-violet-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-violet-800 active:shadow-lg
        transition duration-150 ease-in-out" disabled={load}>
                        Check
                    </button>
                </div>
            </div>
            <div className={"flex flex-col"}>
                <div className={"-m2 overflow-x-auto sm:-mx6 lg:mx-8"}>
                    <div className={"shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"}>
                        <table className={"min-w-full divide-y divide-gray-200"}>
                            <thead className={"bg-gray-50"}>
                            <tr>
                                <th scope={"col"} className={"px-2 py-3 text-left text-xs font-medium text-gray-500" +
                                    " uppercase tracking-winder"}>
                                    Name
                                </th>
                                <th scope={"col"} className={"px-2 py-3 text-left text-xs font-medium text-gray-500" +
                                    " uppercase tracking-winder"}>
                                    Url
                                </th>
                                <th scope={"col"} className={"px-2 py-3 text-center text-xs font-medium text-gray-500" +
                                    " uppercase tracking-winder"}>
                                    Token
                                </th>
                            </tr>
                            </thead>
                            <tbody className={"bg-white divide-y divide-gray-200"}>
                            {users.map(user => (
                                <tr key={user.id}>
                                    <td className={"max-w-xs truncate text-left px-2 py-4 text-sm text-gray-700"}>
                                        <div className={"ml-2"}>
                                            {user.name}
                                            <br></br>
                                            <small className="text-slate-700">
                                                <button type={"button"} onClick={deleteUser} id={user.id}
                                                        name={user.name}
                                                        className={"text-red-600 hover:text-red-900"} disabled={load}>
                                                    Delete user
                                                </button>
                                            </small>
                                        </div>
                                    </td>

                                    <td className={"max-w-xs truncate text-left px-2 py-4 text-sm text-gray-700"}>
                                        <div className={"ml-2"}>
                                            <a className={"text-blue-500 hover:underline"} href={user.url}
                                               target="_blank">
                                                {user.url}
                                            </a>
                                        </div>
                                    </td>

                                    <td className={"px-2 py-4 whitespace-nowrap text-center text-sm font-medium"}>
                                        {user.has_token ? (
                                            <button type={"button"} onClick={deleteUserToken} id={user.id}
                                                    className="inline-block px-3 py-1.5 bg-red-600 mr-2
        text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg
        focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg
        transition duration-150 ease-in-out" disabled={load}>
                                                Delete
                                            </button>
                                        ) : (
                                            <Link href={`/helpers/bff-proxy-users/${user.id}/${user.name}/token/create`}
                                                  className="inline-block px-3 py-1.5 bg-green-600 mr-2
        text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg
        focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg
        transition duration-150 ease-in-out">Create</Link>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </main>
    )
}

Index.layout = page => (
    <MainContentLayout children={page}/>
)
