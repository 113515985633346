import React from 'react'
import MainContentLayout from "../../Layouts/Layout";
import {Head, useForm, usePage} from '@inertiajs/inertia-react'
import {Inertia} from "@inertiajs/inertia";
import Select from "react-select";


export default function Create({groups, users}) {
    const {errors} = usePage().props
    const {data, setData, progress} = useForm({
        name: null,
        description: null,
        groups: [],
        users: [],
        passwords: [],
        _token: usePage().props._token,
    });

    const [load, setLoad] = React.useState(false);
    const [inputList, setInputList] = React.useState([]);

    const handleChecked = (e) => {
        let group_id = e.target.value;
        if (e.target.checked) {
            setData("groups", [...data.groups, group_id]);
        } else {
            setData(
                "groups",
                data.groups.filter((item) => {
                    return item !== group_id;
                })
            );
        }
    };

    const handleInputChange = (e, index) => {
        const {name, value} = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);

    }

    const handleRemovePassword = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    }

    const handleAddPasswordClick = (e) => {
        e.preventDefault();
        setInputList([...inputList, {
            username: null,
            password: null,
            url: null,
        }]);
    }

    const handleAddUser = (e) => {
        let selectedUsers = [];

        for (const value in e) {
            selectedUsers.push(e[value].value)
        }

        setData("users", selectedUsers)
    };

    function handleSubmit(e) {
        e.preventDefault();

        const sendData = {...data, passwords: inputList};
        setData("passwords", inputList);

        Inertia.post('/documents', sendData, {
            onStart: () => {
                setLoad(true);
            },
            onFinish: () => {
                setLoad(false);
            },
        })
    }

    function handleBack(e) {
        e.preventDefault();

        Inertia.get('/documents');
    }

    return (
        <main>

            <Head title={"Create Document"}/>

            <h1 className={"text-2xl"}>Create Document</h1>

            <form onSubmit={handleSubmit} className={"max-w-md mx-auto mt-8"}>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Name
                    </label>
                    <input className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} type={"text"} name={"name"}
                           onChange={(e) =>
                               setData("name", e.target.value)
                           }
                    />
                    {errors.name && <div className={"text-red-700"}>{errors.name}</div>}
                </div>
                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Description
                    </label>
                    <textarea rows={4} className={"shadow appearance-none border rounded w-full py-2" +
                        " px-3" +
                        " text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} name={"description"}
                              onChange={(e) =>
                                  setData("description", e.target.value)
                              }
                    />
                    {errors.description && <div className={"text-red-700"}>{errors.description}</div>}
                </div>
                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Document
                    </label>
                    <input type="file" multiple onChange={e => setData('file', e.target.files)}/>
                    {errors.files && <div className={"text-red-700"}>{errors.files}</div>}
                    {progress && (
                        <progress value={progress.percentage} max="100">
                            {progress.percentage}%
                        </progress>
                    )}
                </div>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Users
                    </label>
                    <Select isMulti
                            options={users}
                            onChange={handleAddUser}
                    />
                    {errors.users && <div className={"text-red-700"}>{errors.users}</div>}
                </div>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Groups
                    </label>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-1">
                        {groups.map((group) => (
                            <div className="form-check" key={group.id}>
                                <label className="form-check-label inline-block text-gray-700" htmlFor={group.id}>
                                    <input
                                        className="form-check-input h-4 w-4 border border-gray-700 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                        type="checkbox" key={group.id} id={group.id}
                                        onChange={handleChecked}
                                        value={group.id}
                                        name={"groups[]"}
                                    />
                                    {errors.groups && <div className={"text-red-700"}>{errors.groups}</div>}
                                    {group.name}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mb-6">
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Passwords
                    </label>
                    {errors.passwords && <div className={"text-red-700 m-1"}>{errors.passwords}</div>}
                    {inputList.map((x, i) => {
                        return (
                            <div className="mt-2 space-y-2" key={"pass_div_" + i}>
                                <input name="username" className={"shadow appearance-none border rounded" +
                                    " w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" +
                                    " focus:shadow-outline border border-solid border-gray-300 p-2 w-full"}
                                       type={"text"} placeholder="Enter Username" key={"pass_username_" + i}
                                       onChange={e => handleInputChange(e, i)}/>
                                {errors[`passwords.${i}.username`] && <div className={"text-red-700 m-1"}>
                                    {errors[`passwords.${i}.username`]}</div>}
                                <input name="password" className={"shadow appearance-none border rounded" +
                                    " w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" +
                                    " focus:shadow-outline border border-solid border-gray-300 p-2 w-full"}
                                       type={"text"} placeholder="Enter Password" key={"password_" + i}
                                       onChange={e => handleInputChange(e, i)}/>
                                {errors[`passwords.${i}.password`] && <div className={"text-red-700 m-1"}>
                                    {errors[`passwords.${i}.password`]}</div>}
                                <input name="url" className={"shadow appearance-none border rounded" +
                                    " w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none" +
                                    " focus:shadow-outline border border-solid border-gray-300 p-2 w-full"}
                                       type={"text"} placeholder="Enter URL" key={"pass_url_" + i}
                                       onChange={e => handleInputChange(e, i)}/>
                                {errors[`passwords.${i}.url`] && <div className={"text-red-700 m-1"}>
                                    {errors[`passwords.${i}.url`]}</div>}
                                    <button className={"inline-block px-6 py-2.5 bg-red-600 text-white" +
                                        " font-medium" +
                                        " text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700" +
                                        " hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none" +
                                        " focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150" +
                                        " ease-in-out"} onClick={() => handleRemovePassword(i)}>Remove</button>
                            </div>
                        )
                            ;
                    })}
                    <div className={"my-2 space-y-2"}>
                        <button className={"inline-block px-6 py-2.5 bg-green-500 text-white" +
                            " font-medium text-xs leading-tight uppercase rounded shadow-md" +
                            " hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg" +
                            " focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg" +
                            " transition duration-150 ease-in-out"}
                                onClick={handleAddPasswordClick}>Add Password
                        </button>
                    </div>
                </div>
                <div className={"flex mb-6 float-right space-x-2"}>
                    <button onClick={handleBack}
                            className={"inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"}>
                        Back
                    </button>
                    <button type={"submit"}
                            className={"inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"}
                            disabled={load}>
                        Submit
                    </button>
                </div>
            </form>
        </main>
    )
}

Create.layout = page => (
    <MainContentLayout children={page}/>
)
