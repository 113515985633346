import React from 'react';

import MainContentLayout from "../../Layouts/Layout";
import {TenantLookup} from '../../Components/Tenant/TenantLookup';
import {TenantResources} from '../../Components/Tenant/TenantResources';

export default function Tenant({tenant, organization, resources, resourceDetail}) {

    return (
        <div className='w-full p-6 flex flex-col gap-y-4'>
            <TenantLookup
                tenantData={tenant}
                organizationData={organization}
            />

            {
                (tenant && organization) && (
                    <TenantResources
                        tenantData={tenant || {}}
                        resourceList={resources || {}}
                        item={resourceDetail || {}}
                    />
                )
            }
        </div>
    )
}

Tenant.layout = page => (
    <MainContentLayout children={page} pageWidth="max-w-[110rem]"/>
)
