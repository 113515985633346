import React from 'react'
import MainContentLayout from "../../Layouts/Layout";
import {Head, Link} from '@inertiajs/inertia-react'
import Pagination from "../../Components/Pagination/Pagination";
import SearchInput from "../../Components/SearchInput/SearchInput";
import FlashMessage from "../../Components/FlashMessage/FlashMessage";

export default function Index({users,filters}) {
    return (
        <main>
            <Head title={"Users"}/>

            <div className={"flex relative justify-between mb-6"}>
                <h1 className={"text-2xl"}>Users</h1>
                <SearchInput filters={filters}/>
                <FlashMessage/>
            </div>

            <div className={"flex flex-col"}>
                <div className={"-m2 overflow-x-auto sm:-mx6 lg:mx-8"}>
                    <div className={"shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"}>
                        <table className={"min-w-full divide-y divide-gray-200"}>
                            <tbody className={"bg-white divide-y divide-gray-200"}>
                            {users.data.map(user => (
                                <tr key={user.id}>
                                    <td className={"px-6 py-4 whitespace-nowrap"}>
                                        <div className={"flex items-center"}>
                                            <div className={"flex-shrink-0 h-10 w-10"}>
                                                <img className={"h-10 w-10 rounded-full"}
                                                     src={user.avatar ?? '/images/alternative-profile-photo.jpeg'}
                                                     alt=""/>
                                            </div>
                                            <div className={"ml-4"}>
                                                <div className={"text-sm font-medium text-gray-900"}>
                                                    {user.name}
                                                </div>
                                                <div className={"text-sm text-gray-500"}>
                                                    {user.email}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={"px-6 py-4 whitespace-nowrap"}>
                                    <span className={"px-2 inline-flex text-xs leading-5 font-semibold rounded-full " +
                                        (user.active  ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800")}>
                                        {user.active ? 'Active' : 'Inactive'}
                                    </span>
                                    </td>
                                    <td className={"px-6 py-4 whitespace-nowrap text-right text-sm font-medium"}>
                                        <Link href={`/users/${user.id}/edit`} className={"text-indigo-600" +
                                            " hover:text-indigo-900"}>Edit</Link>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Paginator */}
            <div>
                <Pagination pagination={users}/>
            </div>
        </main>
    )
}

Index.layout = page => (
    <MainContentLayout children={page}/>
)
