import React from 'react'
import AuthLayout from '../Layouts/Auth'

export default function Login() {
    return (
        <AuthLayout>
            <div className="text-center">
                <h6 className="text-gray-600 text-sm font-bold">
                    You have successfully registered, but this account is not active.
                    Please contact your administrator for more information
                </h6>
            </div>
        </AuthLayout>

    )
}
