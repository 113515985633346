import React from 'react'

export const AppStatusPill = ({ monitor }) => {
    const statusColor = {
        0: "bg-yellow-400", // paused
        1: "bg-blue-600", // not checked yet
        2: "bg-green-600", // up
        8: "bg-orange-600", // seems down
        9: "bg-red-600" // down
    }[monitor.status]
    const textStyle = monitor.status === 2? "text-black": "text-white pr-16"
    const backgroundColor = monitor.status === 2? "": statusColor;

    /*
    const appName = ["PRODUCTION", "STAGING", "HUB", "APP"].reduce((curName, toRemove) => {
        return curName.replace(toRemove, "")
    }, monitor.friendly_name.toUpperCase())
    */
    

    return (
        <div className={`max-w-fit flex gap-x-2 justify-start items-center p-2 rounded-xl ${backgroundColor}`}>
            <span className={`min-w-[1.5rem] min-h-[1.5rem] max-w-6 max-h-6 rounded-md border-2 border-white ${statusColor}`}></span>
            <h2 className={`font-bold whitespace-nowrap text-lg ${textStyle}`}>{monitor.friendly_name.toUpperCase()}</h2>
        </div>
    )
} 