import React from 'react'
import MainContentLayout from "../../Layouts/Layout";
import {Head, useForm, usePage} from '@inertiajs/inertia-react'
import {Inertia} from "@inertiajs/inertia";


export default function Edit({user, groups}) {
    const {errors} = usePage().props
    const {data, setData} = useForm({
        name: user.name,
        email: user.email,
        groups: user.groups.map(group => group.id),
        active: user.active,
        _token: usePage().props._token,
    });

    const [load, setLoad] = React.useState(false);

    function handleSubmit(e) {
        e.preventDefault();

        Inertia.put('/users/' + user.id, data, {
            onStart: () => {
                setLoad(true);
            },
            onFinish: () => {
                setLoad(false);
            },
        })
    }

    const handleChecked = (event) => {
        const group_id = event.target.value;
        const checked = event.target.checked;

        if (checked) {
            setData("groups", [...data.groups, Number(group_id)]);
        }
        else {
            setData("groups", data.groups.filter(id => id != group_id));
        }
    };

    function handleBack(e) {
        e.preventDefault();

        Inertia.get('/users');
    }

    return (
        <main>

            <Head title={"Edit User"}/>

            <h1 className={"text-2xl"}>Edit User</h1>

            <form onSubmit={handleSubmit} className={"max-w-md mx-auto mt-8"}>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Name
                    </label>
                    <input className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} type={"text"} name={"name"}
                           onChange={(e) =>
                               setData("name", e.target.value)
                           }
                           value={data.name}/>
                    {errors.name && <div className={"text-red-700"}>{errors.name}</div>}
                </div>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Email
                    </label>
                    <input className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} type={"text"}
                           value={data.email} disabled/>
                </div>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Group
                    </label>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1">
                        {
                            groups.map((group) => (
                                <div className="form-check" key={group.id}>
                                    <label className="form-check-label inline-block text-gray-700" htmlFor={group.id}>
                                        <input
                                            className="form-check-input h-4 w-4 border border-gray-700 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                                            type="checkbox"
                                            id={group.id}
                                            onChange={handleChecked}
                                            value={group.id}
                                            checked={data.groups.includes(group.id)}
                                        />
                                        {group.name}
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                    {errors.groups && <div className={"text-red-700"}>{errors.groups}</div>}
                </div>


                <label className="flex items-center cursor-pointer relative mb-4">
                    <input type="checkbox" className="sr-only" value={data.active} checked={data.active}
                           onChange={(e) =>
                               setData("active", !data.active)
                           }/>
                    <div className={"toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-11 rounded-full"}></div>
                    <span className={"ml-3 text-gray-900 text-sm font-medium"}>Active</span>
                    {errors.active && <div className={"text-red-700"}>{errors.active}</div>}
                </label>

                <div className={"flex mb-6 float-right space-x-2"}>
                    <button onClick={handleBack} className={"inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"}>
                        Back
                    </button>
                    <button type={"submit"} className={"inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"}
                            disabled={load}>
                        Submit
                    </button>
                </div>


            </form>

        </main>
    )
}

Edit.layout = page => (
    <MainContentLayout children={page}/>
)
