import { Inertia } from "@inertiajs/inertia";
import { Head } from '@inertiajs/inertia-react';
import React, { useState, useEffect } from 'react';
import dayjs from "dayjs";
import { IoIosClose } from 'react-icons/io';

import MainContentLayout from "../../Layouts/Layout";


export default function LogGrouping({logs}) {
    const [searchParams, setSearchParams] = useState({})
    const filterList = ["tenant_id", "device_id", "dateFrom", "dateTo", "tag"]

    const [ logList, setLogList ] = useState(logs || []);
    const [ searchText, setSearchText ] = useState("");

    useEffect(() => {
        setLogList(logs);
        searchLogList(searchText);
    }, [logs]);

    const validateDate = (dateTime) => {
        if(!dateTime) {
            return null;
        }

        let [date, time] = dateTime.trim().split(" ")
        if(!date) {
            return null;
        }

        date = date.split("-").map(item => Number(item))
        time = (time || "0:0:0").split(":").map(item => Number(item))

        const processedDate = dayjs()
            .set("year", date[0])
            .set("month", date[1] - 1)
            .set("date", date[2])
            .set("hour", time[0])
            .set("minute", time[1])
            .set("second", time[2])

        return processedDate.format("YYYY-MM-DD HH:mm:ss")
    }

    const processDateValue = (key, value) => {
        if(!value) {
            return "";
        }
        
        const lastInput = value[value.length - 1];
        const validInputs = ["-", ":", "0", " "]

        if(!Number(lastInput) && !validInputs.includes(lastInput)) {
            value = value.slice(0, -1);
            document.getElementById(`${key}_search`).value = value;
        }

        return value;
    }

    const processSearchParams = () => {
        const params = {
            ...searchParams,
            dateTo: validateDate(searchParams.dateTo),
            dateFrom: validateDate(searchParams.dateFrom)
        };

        Object.keys(params).forEach(key => {
            if(typeof params[key] === "string") {
                params[key] = params[key]?.trim();
            }

            if(!params[key]) {
                delete params[key]
            }
        })

        return params;
    }

    const filterResults = () => {
        const params = processSearchParams();
        Inertia.get("/log-grouping", params, {preserveState: true});
    }

    const searchLogList = (searchValue) => {
        if(!searchValue) {
            setLogList(logs);
            document.getElementById(`tag_search`).value = "";
            return;
        }

        const searchRes = logs.filter(log => log.tag.includes(searchValue?.toUpperCase()))
        setLogList(searchRes);
    }

    const updateSearchParams = (key, value) => {
        if(key === "tag") {
            setSearchText(value);
            searchLogList(value);
            return;
        }
        
        const paramsCopy = {...searchParams, [key]: value}

        if(key.startsWith("date")) {
            value = processDateValue(key, value)
        }
        
        if(!value) {
            delete paramsCopy[key];
            document.getElementById(`${key}_search`).value = "";
        }

        setSearchParams(paramsCopy);
    }

    const openLogTable = (tag) => {
        const params = processSearchParams();

        Inertia.get("/logs", {...params, level: "ERROR", tag}, {preserveState: true});
    }

    const containerStyle = "shadow overflow-hidden border-b border-gray-200 rounded-lg bg-slate-50";

    return (
        <main>
            <Head title={"LogGrouping"}/>

            <form 
                onSubmit={(event) => {
                    event.preventDefault();
                    filterResults();
                }}
                className="flex flex-col mb-4 md:flex-row md:justify-between md:flex-wrap"
            >
                {
                    filterList.map((curFilter, index) => {
                        return (
                            <div 
                                key={index} 
                                className={`${containerStyle} flex mb-2 md:w-[49%]`}
                            >
                                <input
                                    type="text"
                                    key={curFilter}
                                    id={`${curFilter}_search`}
                                    className='w-full rounded-lg outline-none p-2 bg-slate-50'
                                    placeholder={curFilter.startsWith("date")? `${curFilter}(DD-MM-YYYY hh:mm:ss)`: `${curFilter}...`}
                                    onChange={(event) => updateSearchParams(curFilter, event.target.value)}
                                />

                                <button
                                    type="button"
                                    className='pr-2'
                                    onClick={() => updateSearchParams(curFilter, null)}
                                >
                                    <IoIosClose className='text-slate-400 text-2xl'/>
                                </button>
                            </div>
                        )
                    })
                }
                <button type="submit" className="flex justify-center rounded-lg px-4 py-2 bg-blue-600 focus:hover:bg-blue-500 hover:bg-blue-500 text-white font-medium mb-2 md:w-[25%]">
                    Filter
                </button>
            </form>

            {
                logList.length? (
                    logList.map((group, index) => {
                        return (
                            <div
                                key={index} 
                                className={`${containerStyle} mb-4 flex justify-between items-center gap-x-8`}
                            >
                                <button
                                    className="min-w-[40%] flex rounded-l-lg p-4 bg-red-600 transition underline decoration-white hover:decoration-2"
                                    onClick={() => openLogTable(group.tag)}
                                >
                                    <span className={`text-white font-medium`}>{group.tag}</span>
                                </button>

                                <div className={`min-w-[15%] text-slate-400 font-medium pr-8`}>
                                    count: <span className="text-xl">{group.total}</span>
                                </div>
                            </div>
                        )
                    })
                ): (
                    <div className="w-full flex justify-center p-10">
                        <span className="text-lg font-medium text-slate-600">No results</span>
                    </div>
                )

            }
        </main>
    )
}

LogGrouping.layout = page => (
    <MainContentLayout children={page}/>
)
