import { Inertia } from "@inertiajs/inertia";
import { Head, usePage } from '@inertiajs/inertia-react';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';

import FlashMessage from "../../Components/FlashMessage/FlashMessage";
import { LogDataDrawer } from '../../Components/Logs/LogDataDrawer';
import { Pagination } from "../../Components/Logs/LogPagination";
import { LogTable } from '../../Components/Logs/LogTable';
import MainContentLayout from "../../Layouts/Layout";

export default function Logs({logs}) {

    const [selectedLog, setSelectedLog] = useState({});
    const [searchParams, setSearchParams] = useState({})



    const url = usePage().url;

    const validateDate = (dateTime) => {
        if(!dateTime) {
            return null;
        }

        let [date, time] = dateTime.trim().split(" ")
        if(!date) {
            return null;
        }

        date = date.split("-").map(item => Number(item))
        time = (time || "0:0:0").split(":").map(item => Number(item))

        const processedDate = dayjs()
            .set("year", date[0])
            .set("month", date[1] - 1)
            .set("date", date[2])
            .set("hour", time[0])
            .set("minute", time[1])
            .set("second", time[2])

        return processedDate.isValid()? processedDate.format("YYYY-MM-DD HH:mm:ss"): null
    }

    const filterSearch = () => {
        setSelectedLog({});

        const [dateFrom, dateTo] = searchParams?.log_created_at?.split("--") || [null, null];

        const params = {
            ...searchParams,
            dateFrom: validateDate(dateFrom),
            dateTo: validateDate(dateTo)
        }
        delete params.log_created_at;

        Object.keys(params).forEach(key => {
            if(typeof params[key] === "string") {
                params[key] = params[key]?.trim();
            }

            if(!params[key]) {
                delete params[key]
            }
        })

        Inertia.get(url.replace(/\?.*$/g, ""), params, {preserveState: true});
    }

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                console.log('Close')
                setSelectedLog({});
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    return (
        <main>
            <Head title={"Logs"}/>

            <div className={"flex relative justify-between mb-6"}>
                <div className={"flex items-center"}>
                    <h1 className={"text-2xl"}>Logs</h1>
                    <FlashMessage/>
                </div>
            </div>
            <div className={"flex flex-col"}>
                <div className={"overflow-x-auto"}>
                    <div className={"shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"}>
                        <div
                            id="logTableContainer"
                            className='transition-all delay-300'
                        >
                            <div className='flex justify-center border-t py-4'>
                                <Pagination
                                    logs={logs}
                                    searchParams={searchParams}
                                />
                            </div>

                            <form
                                id="searchQuery"
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    filterSearch()
                                }}
                            >
                                <button type="submit" className='hidden'/>
                                <LogTable
                                    logs={logs}
                                    searchParams={searchParams}
                                    setSearchParams={setSearchParams}
                                    selectedLogId={selectedLog.id}
                                    selectLog={setSelectedLog}
                                />
                            </form>

                            <div className='w-full flex justify-center pt-2'>
                                <Pagination
                                    logs={logs}
                                    searchParams={searchParams}
                                />
                            </div>
                        </div>

                        <LogDataDrawer
                            id="logDataDrawer"
                            logData={selectedLog}
                            setSelectedLog={setSelectedLog}
                        />
                    </div>
                </div>
            </div>
        </main>
    )
}

Logs.layout = page => (
    <MainContentLayout children={page}/>
)
