import React from 'react'
import MainContentLayout from "../../../Layouts/Layout";
import {Head, useForm, usePage} from '@inertiajs/inertia-react'
import {Inertia} from "@inertiajs/inertia";

export default function CreateUser({userId}) {
    const {errors} = usePage().props
    const {data, setData} = useForm({
        name: null,
        url: null,
        _token: usePage().props._token,
    });
    const [load, setLoad] = React.useState(false);

    function handleSubmit(e) {
        e.preventDefault();

        Inertia.post('/helpers/bff-proxy-users/store', data, {
            onStart: () => {
                setLoad(true);
            },
            onFinish: () => {
                setLoad(false);
            },
        })
    }

    function handleBack(e) {
        e.preventDefault();

        Inertia.get('/helpers/bff-proxy-users');
    }

    return (
        <main>

            <Head title={"Create user"}/>

            <h1 className={"text-2xl"}>Create BffProxy user</h1>

            <form onSubmit={handleSubmit} className={"max-w-md mx-auto mt-8"}>
                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Name
                    </label>
                    <input className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} type={"text"} name={"name"}
                           onChange={(e) =>
                               setData("name", e.target.value)
                           }
                    />
                    {errors.name && <div className={"text-red-700"}>{errors.name}</div>}
                </div>

                <div className={"mb-6"}>
                    <label className={"block mb-2 uppercase font-bold text-xs text-gray-700"}>
                        Url
                    </label>
                    <input className={"shadow appearance-none border rounded w-full py-2 px-3 text-gray-700" +
                        " leading-tight focus:outline-none focus:shadow-outline border border-solid border-gray-300" +
                        " p-2 w-full"} type={"text"} name={"url"}
                           onChange={(e) =>
                               setData("url", e.target.value)
                           }
                    />
                    {errors.url && <div className={"text-red-700"}>{errors.url}</div>}
                </div>

                <div className={"flex mb-6 float-right space-x-2"}>
                    <button onClick={handleBack}
                            className={"inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out"}>
                        Back
                    </button>
                    <button type={"submit"}
                            className={"inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"}
                            disabled={load}>
                        Submit
                    </button>
                </div>
            </form>
        </main>
    )
}

CreateUser.layout = page => (
    <MainContentLayout children={page}/>
)
