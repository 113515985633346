import React from 'react'
import MainContentLayout from "../../Layouts/Layout";
import {Head} from '@inertiajs/inertia-react'
import {Inertia} from "@inertiajs/inertia";
import FlashMessage from "../../Components/FlashMessage/FlashMessage";
import SearchInput from "../../Components/SearchInput/SearchInput";

export default function Index({appUrls, filters}) {
    const links = [];

    for (name in appUrls) {
        let temp = {};
        temp['name'] = name
        temp['url'] = appUrls[name]

        links.push(temp);
    }

    function handleTelescopeClearButton(e) {
        e.preventDefault();
        Inertia.get(`/app-monitor/disable/${e.target.value}`);
    }

    return (
        <main>
            <Head title={"Application Monitoring"}/>

            <div className={"relative flex justify-between mb-6"}>
                <div className={"flex items-center"}>
                    <h1 className={"text-2xl"}>Application Monitoring</h1>
                    <FlashMessage/>
                </div>

                <SearchInput filters={filters}/>
            </div>
            <div className={"flex flex-col"}>
                <div className={"-m2 overflow-x-auto sm:-mx6 lg:mx-8"}>
                    <div className={"shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"}>
                        <table className={"min-w-full divide-y divide-gray-200"}>
                            <thead className={"bg-gray-50"}>
                            <tr>
                                <th scope={"col"} className={"px-6 py-3 text-left text-xs font-medium text-gray-500" +
                                    " uppercase tracking-winder"}>
                                    App Name
                                </th>
                                <th scope={"col"} className={"px-6 py-3 text-left text-xs font-medium text-gray-500" +
                                    " uppercase tracking-winder"}>
                                    Url
                                </th>
                                <th scope={"col"} className={"px-6 py-3 text-left text-xs font-medium text-gray-500" +
                                    " whitespace-nowrap uppercase tracking-winder text-center"}>
                                    Options
                                </th>
                            </tr>
                            </thead>
                            <tbody className={"bg-white divide-y divide-gray-200"}>
                            {links.map(link => (
                                <tr key={link.name}>
                                    <td className={"px-6 py-4 text-sm text-gray-500"}>
                                        {link.name}
                                    </td>
                                    <td className={"px-6 py-4 text-sm text-gray-500"}>
                                        <a
                                            className={"text-blue-500 hover:underline"}
                                            href={link.url}
                                            target="_blank"
                                        >
                                            {link.url}
                                        </a>
                                    </td>
                                    <td className={"px-6 py-4 text-sm text-gray-500 text-center"}>
                                        <div className="flex space-x-2 justify-center">
                                            <a type="button"
                                                    className="inline-block px-4 py-1.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
                                               href={`/app-monitor/authorize-request/${link.name}/telescope`} target={"_blank"}>
                                                Telescope
                                            </a>
                                            <a type="button"
                                                    className="inline-block px-4 py-1.5 bg-orange-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-orange-700 hover:shadow-lg focus:bg-orange-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-orange-900 active:shadow-lg transition duration-150 ease-in-out"
                                               href={`/app-monitor/authorize-request/${link.name}/log-viewer`} target={"_blank"}>
                                                Logs
                                            </a>
                                            <button type="button"
                                                    className="inline-block px-4 py-1.5 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out"
                                                    onClick={handleTelescopeClearButton} value={link.name}>Disable
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
    )
}

Index.layout = page => (
    <MainContentLayout children={page}/>
)
