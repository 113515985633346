import React, { useEffect } from 'react';
import { IoIosClose } from 'react-icons/io';
import { Inertia } from "@inertiajs/inertia";
import { usePage } from '@inertiajs/inertia-react';

import { LogRow } from './LogRow';

export function LogTable({ logs, selectedLogId, selectLog, searchParams, setSearchParams }) {

    console.log(logs)

    const ignoreCol = ['id', 'updated_at', 'send_time', 'source', 'data'];
    const searchable = ['cash_register_code', 'device_id', 'tenant_id', 'user', 'log_created_at', 'tag', 'level'];

    const upperCaseFormat = ['tag', 'level'];
    const lowerCaseFormat = ['cash_register_code', 'device_id', 'tenant_id', 'user'];

    const columnListFallback = ["cash_register_code", "device_id", "tenant_id", "user", "log_created_at", "data", "tag", "level", "created_at", "updated_at"]
    const columnList = logs?.data?.length ? Object.keys(logs['data'][0]): columnListFallback;

    const dateFields = ["log_created_at"]

    let siblingStyle = "";
    const url = usePage().url;

    const processDateValue = (key, value) => {
        if(!value) {
            return "";
        }

        const lastInput = value[value.length - 1];
        const validInputs = ["-", ":", ".", "0", " "]

        if(!Number(lastInput) && !validInputs.includes(lastInput)) {
            value = value.slice(0, -1);
            document.getElementById(`${key}_search`).value = value;
        }

        return value;
    }

    const addSearchParam = (newParam, newParamValue) => {
        let formattedParamValue = newParamValue.trim();

        if(dateFields.includes(newParam)) {
            newParamValue = processDateValue(newParam, newParamValue)
        }

        if(upperCaseFormat.includes(newParam)) {
            formattedParamValue = formattedParamValue.toUpperCase();
        }
        else if(lowerCaseFormat.includes(newParam)) {
            formattedParamValue = formattedParamValue.toLowerCase();
        }

        const newSearchParams = {...searchParams};

        if(formattedParamValue === "") {
            delete newSearchParams[newParam];
        }
        else {
            newSearchParams[newParam] = formattedParamValue;
        }

        setSearchParams(newSearchParams);
        localStorage.setItem('searchParams', JSON.stringify(newSearchParams));
    }

    const clearInput = (inputKey) => {
        const input = document.getElementById(`${inputKey}_search`);
        input.value = "";

        const newSearchParams = {...searchParams};
        delete newSearchParams[inputKey];

        setSearchParams(newSearchParams);
        localStorage.setItem('searchParams', JSON.stringify(newSearchParams));

        selectLog({});
        Inertia.get(url.replace(/\?.*$/g,""), newSearchParams, { preserveState: true });
    }

    const headers = columnList.reduce((headerList, key) => {
        if(ignoreCol.includes(key)) {
            return headerList;
        }

        const shouldShowSearch = searchable.includes(key);

        let formattedName = key.charAt(0).toUpperCase() + key.slice(1) // uppercase first letter
        formattedName = formattedName.replace(/_/g, " "); // remove underscores

        const specificStyle = " " + siblingStyle
        siblingStyle = ""

        if(formattedName == 'Level') {
            siblingStyle = " pl-6";
        }

        headerList.push(
            <th
                key={formattedName}
                className={`${specificStyle} align-top pr-6 py-6 first:px-6`}
            >
                <div className='whitespace-nowrap text-slate-400 font-medium text-left pl-1'>
                    {formattedName}
                </div>

                {
                    shouldShowSearch && (
                        <div className='flex justify-between rounded-lg bg-white'>
                            <input
                                id={`${key}_search`}
                                type="text"
                                className='rounded-lg flex-1 outline-none p-1'
                                placeholder={`${formattedName}...`}
                                onChange={(event) => addSearchParam(key, event.target.value)}
                            />
                            {

                                <button
                                    type="button"
                                    className='pr-2'
                                    onClick={() => clearInput(key)}
                                >
                                    <IoIosClose className='text-slate-400 text-2xl'/>
                                </button>
                            }
                        </div>
                    )
                }
            </th>
        )

        return headerList;
    }, [])

    useEffect(() => {
        const savedSearchParams = JSON.parse(localStorage.getItem('searchParams'));
        if(!savedSearchParams) {
            return;
        }

        Object.keys(savedSearchParams).forEach((key) => {
            const value = savedSearchParams[key];
            document.getElementById(`${key}_search`).value = value;
        })

        setSearchParams(savedSearchParams)

        return () => {
            localStorage.setItem('searchParams', null);
        }
    }, []);

    return (
        <div className='w-full overflow-x-auto'>
            <div className="min-w-fit rounded-lg bg-slate-50 border drop-shadow-md">
                <table className="w-full table-auto rounded-lg border-slate">
                    <thead>
                        <tr>
                            {headers}
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        {
                            (logs['data'] || []).map(log =>
                                <LogRow
                                    key={log.id}
                                    ignoreCell={ignoreCol}
                                    logData={log}
                                    isSelected={log.id === selectedLogId}
                                    selectLog={selectLog}
                                />
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

