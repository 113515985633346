const invoiceGroups = {
    "AUDIT_PACKAGE": {
        "INVOICE_CREATION": [
            "invoice successfully signed",
            "successfully made new invoice",
            "successfully made new audit package",
            "successfully handled new audit",
        ],

        "AP_SENDING": [
            "sending audit package from storage",
            "audit package successfully sent",
            "audit package sent to tax core",
        ],

        "QUEUE_SAVING": [
            "queue not empty, audit package will be saved to storage",
            "queue - getting storage prefix for saving",
            "queue - saving audit package",
            "queue - successfully saved audit package",
        ],
        "QUEUE_REMOVING": [
            "queue - removing audit package",
            "queue - successfully removed audit package",
            "audit package removed from the storage"
        ],
    
        "OFFLINE_AP_SAVING": [
            "saving audit package to offline storage",
            "audit package successfully saved to offline storage",
            "offlinefiles - getting storage prefix for saving",
            "offlinefiles - saving audit package",
            "offlinefiles - successfully saved audit package",
        ],
        "OFFLINE_AP_REMOVING": [
            "offlinefiles - removing audit package",
            "deleting offline json file",
            "deleted offline file successfully",
        ],
        "AP_FAIL": [
            "failed to delete offline json file",
            "failed to save audit package to offline storage",
            "failed to create new audit package",
            "offlineFiles - failed to save audit package",
            "failed to send audit package to tax core",
            "failed to send audit package",
            "queue - failed to save audit package",
        ],
    },
    "DOCUMENT": {
        "INVOICE_PRINTING": [
            "formatting invoice",
            "preparing to print out invoice",
            "successfully printed out invoice",
        ],
        "OFFLINE_DOC_SAVING": [
            "saving the document to a offline file",
            "document successfully saved to offline storage",
        ],
        "OFFLINE_DOC_REMOVING": [
            "deleting offline json file",
            "deleted offline file successfully",
        ],
        "DOC_FAIL": [
            "failed to save document after multiple retries",
            "failed to delete offline json file",
            "failed to save the document to a offline file",
        ],
    },
}

const getLogCategory = (log) => {
    let logGroup = "GROUP_NOT_FOUND"
    
    const actionMsg = log?.data?.action || "";
    const tag = log?.tag || "";
    
    const group = tag.match(/(AUDIT_PACKAGE|DOCUMENT)/)
    if(!group) {
        return logGroup;
    }
    
    const subGroupList = invoiceGroups[group[0]] || {};
    for(let groupKey of Object.keys(subGroupList)) {
        const subGroup = subGroupList[groupKey];

        if(subGroup.includes(actionMsg.toLowerCase())) {
            logGroup = groupKey;
            break;
        }
    }

    return logGroup;
}

const checkIfSaved = (logList) => {
    const taxCoreSuccessMsg = "audit package successfully sent";
    const salesAppSuccessMsg = "Document saved";
    
    const isSaved = {taxCore: false, salesApp: false};
    const reversedList = [...logList].reverse();
    
    for(const log of reversedList) {
        const actionMsg = log.data.action;
        if(actionMsg === taxCoreSuccessMsg) {
            isSaved.taxCore = true;
        }
        if(actionMsg === salesAppSuccessMsg) {
            isSaved.salesApp = true;
        }

        if(isSaved.salesApp && isSaved.taxCore) {
            break;
        }
    }

    return isSaved;
}

const shouldSkipLog = (log, showApLogs, showDocLogs) => {
    if(log.tag.includes("AUDIT_PACKAGE") && !showApLogs) {
        return true;
    }
    if(log.tag.includes("DOCUMENT") && !showDocLogs) {
        return true;
    }
    
    return false
}

export const processLogs = (invoiceLogs, showApLogs, showDocLogs) => {
    const isInvoiceSaved = checkIfSaved(invoiceLogs);
    let duplicateLogs = false;

    const processedLogs = invoiceLogs.reduce((logCollection, curLog) => {
        if(shouldSkipLog(curLog, showApLogs, showDocLogs)) {
            return logCollection;
        }
        
        const key = curLog.data.action;
        if(!logCollection[key]) {
            const category = getLogCategory(curLog);

            logCollection[key] = {
                ...curLog, 
                num: 0,
                category
            }
        }

        logCollection[key].num += 1;
        duplicateLogs = logCollection[key].num >= 2;

        return logCollection;
    }, {});
    const logList = Object.values(processedLogs);

    return {
        logList,
        isInvoiceSaved,
        duplicateLogs
    }
}

export const groupLogs = (logList) => {
    return logList.reduce((logGroups, curLog) => {
        const category = curLog.category;
        if(!logGroups[category]) {
            logGroups[category] = []
        }

        logGroups[category].push(curLog);
        return logGroups;
    }, {});
}