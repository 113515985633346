import dayjs from "dayjs";
import React from "react";
import { IoIosCopy } from 'react-icons/io';

export function InvoiceTraceLog({ logData }) {
    const logCreatedAt = dayjs(logData.log_created_at).subtract(2, "hour")
    const logSavedAt = dayjs(logData.created_at)
    
    const timeDiff = () => {
        const secInDay = 24 * 3600;
        const difInSec = logSavedAt.diff(logCreatedAt, "second");
        
        const timeDiff = dayjs(0).subtract(1, "hour").set("seconds", difInSec).format("HH:mm:ss");
        if(difInSec < secInDay) {
            return timeDiff;
        }

        const days = Math.floor(difInSec / secInDay);
        return `${days < 10? "0": ""}${days}:${timeDiff}`
    }

    const containerStyle = "shadow overflow-hidden border-b border-gray-200 rounded-lg ";

    const getIndicatorColor = () => {
        return {
            INFO: " bg-blue-600",
            ERROR: " bg-red-500",
            WARN: " bg-yellow-400",
        }[logData.level]
    }

    return (
        <div
            className={`${containerStyle} flex flex-col gap-y-2 mb-4 p-2 bg-slate-100`}
        >
            <div className="w-full flex items-center justify-between">
                <div className={`${getIndicatorColor()} ${containerStyle} px-2 py-1 font-medium text-white`}>
                    {logData.num} x {logData.tag}
                </div>

                <div className="flex gap-x-4">
                    <div className={`${containerStyle} px-2 py-1 bg-slate-50`}>
                        <span className="font-medium">Created:</span> {logCreatedAt.format("DD.MM.YYYY HH:mm:ss")}
                    </div>
                    <div className={`${containerStyle} px-2 py-1 bg-slate-50`}>
                        <span className="font-medium">Saved:</span> {logSavedAt.format("DD.MM.YYYY HH:mm:ss")}
                    </div>
                    <div className={`${containerStyle} px-2 py-1 bg-slate-50`}>
                        <span className="font-medium">Difference:</span> {timeDiff()}
                    </div>
                </div>
            </div>

            <div className="flex justify-between">
                <div className="flex flex-col gap-x-4 pl-4">
                    {
                        Object.keys(logData.data).map((key) => {
                            const value = logData.data[key];

                            return (
                                <div key={logData.log_created_at + key} className="flex gap-x-2">
                                    <span className="font-medium">{key.toUpperCase() + ": "}</span>
                                    {
                                        value.length > 100? (
                                            <span className="flex items-center gap-x-2">
                                                {`${value.slice(0, 100)}...`}
                                                <button onClick={() => navigator.clipboard.writeText(value)}>
                                                    <IoIosCopy className='text-lg hover:text-gray-600 active:text-gray-400'/>
                                                </button> 
                                            </span>
                                        ): (
                                            <span>{value}</span>
                                        )
                                    }
                                </div>
                            )
                        })
                    }
                </div>

                <div>
                    <button></button>
                </div>
            </div>
        </div>
    )
}
