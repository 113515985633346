import React, { useState, useEffect } from 'react';
import { MonitorGroupCard } from '../../Components/Dashboard/MonitorGroupCard';
import { AppStatusPill } from '../../Components/Dashboard/AppStatusPill';

export default function Dashboard({ uptimeRobotStatues }) {
    const [hubGroup, setHubGroup] = useState([]);
    const [appGroup, setAppGroup] = useState([]);
    const [hubGroupProd, setHubGroupProd] = useState([]);
    const [appGroupProd, setAppGroupProd] = useState([]);
    const [otherGroup, setOtherGroup] = useState([]);

    const setParentHeight100 = () => {
        const bodyElem = document.getElementsByTagName("body")[0]
        const htmlElem = document.getElementsByTagName("html")[0]
        const appElem = document.querySelector("div#app");

        bodyElem.style.height = "100%";
        htmlElem.style.height = "100%";
        appElem.style.height = "100%";

        appElem.style.backgroundSize = "cover"
        appElem.style.backgroundRepeat = "no-repeat"
    }
    setParentHeight100();

    const groupUpMonitors = (monitorList) => {
        return monitorList.reduce((groups, monitor) => {
            const nameSplit = monitor.friendly_name.split(" ");
            const namePrefix = nameSplit[0].toLowerCase();
            const namePostfix = nameSplit[nameSplit.length - 1].toLowerCase();

            const appStatusPill = (
                <AppStatusPill key={monitor.id} monitor={monitor} />
            )

            const isValidPrefix = namePrefix === "production" || namePrefix === "staging"
            const isValidPostfix = namePostfix === "hub" || namePostfix === "app"

            if(isValidPrefix && isValidPostfix) {
                groups[namePrefix][namePostfix].push(appStatusPill);
            }
            else {
                groups.other.push(appStatusPill);
            }
            return groups;
        }, {
            production: {
                hub: [],
                app: [],
            },
            staging: {
                hub: [],
                app: [],
            },
            other: [],
        })
    }

    useEffect(() => {
        function getGroupedMonitors() {
            const groupedMonitors = groupUpMonitors(uptimeRobotStatues.monitors);

            setHubGroup(groupedMonitors.staging.hub);
            setAppGroup(groupedMonitors.staging.app);
            setHubGroupProd(groupedMonitors.production.hub);
            setAppGroupProd(groupedMonitors.production.app);
            setOtherGroup(groupedMonitors.other);
        }
        getGroupedMonitors();
    }, [ uptimeRobotStatues ]);

    return (
        <div className='h-full flex self gap-x-4 px-6 py-3'>
            <div className='flex flex-col flex-1 gap-y-4'>
                <MonitorGroupCard
                    title="PRODUCTION HUB"
                    monitorList={ hubGroupProd }
                />
                <MonitorGroupCard
                    title="PRODUCTION APP"
                    monitorList={ appGroupProd }
                />
            </div>
            <div className='flex flex-col flex-1 gap-y-4'>
                <MonitorGroupCard
                    title="STAGING HUB"
                    monitorList={ hubGroup }
                />
                <MonitorGroupCard
                    title="STAGING APP"
                    monitorList={ appGroup }
                />
            </div>
            <div className='flex flex-col flex-1 gap-y-4'>
                <MonitorGroupCard
                    title="OTHER"
                    monitorList={ otherGroup }
                />
            </div>
        </div>
    )
}
