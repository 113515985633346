import React from 'react'
import Nav from "./Nav";
import {Head} from "@inertiajs/inertia-react";

export default function Layout({children, pageWidth = ""}) {

    return (
        <main>
            <Head title={"SuperIS"}/>
            <Nav/>
            <section className={"p-6"}>
                <div className={`${pageWidth} mx-auto`}>
                    <article>{children}</article>
                </div>
            </section>
        </main>
    )
}
