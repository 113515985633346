import React, { useState, useEffect } from 'react';
import { IoIosClose } from 'react-icons/io';
import { Inertia } from '@inertiajs/inertia';
import { DisplayJSON } from "../DisplayJSON";


export const TenantLookup = ({tenantData, organizationData}) => {
    const [ tenant, setTenant ] = useState(null);
    const [ organization, setOrganization ] = useState(null);
    const [ tenantName, setTenantName ] = useState(tenant?.name || '');
    const url = '/tenant';

    const fetchOrganization = (tenantId) => {
        Inertia.get(
            `${url}/${tenantName}`,
            {
                tenantId: tenantId,
            },
            {
                preserveState: true,
                preserveScroll: true,
                only: ['organization']
            }
        );
    }

    const fetchTenant = (event) => {
        event.preventDefault();
        const newTenantName = event.target.tenantNameInput.value;

        setTenantName(newTenantName);
        setTenant(null);
        setOrganization(null);

        Inertia.get(
            `${url}/${newTenantName}`,
            {},
            {
                preserveState: true,
                preserveScroll: true,
                only: ['tenant']
            }
        );
    }

    const shouldFetchOrganization = () => {
        const isNewTenant = tenantData?.id !== tenant?.id;
        return !organization || isNewTenant;
    }

    useEffect(() => {
        function setStates() {
            /**
             * 1st render - no data so nothing happens
             * 2nd render - tenant data available so we set the tenant state and fetch the organization
             * 3rd render - organization data available so we set the organization state
             */
            if(tenantData) {
                setTenant(tenantData);
            }
            else if(!tenant) {
                return;
            }

            if(organizationData) {
                setOrganization(organizationData);
            }
            else if(shouldFetchOrganization()) {
                fetchOrganization(tenantData?.id);
            }
        }
        setStates();
    }, [tenantData, organizationData]);

    return (
        <div className='flex flex-col gap-y-4'>
            <div className='flex items-center bg-slate-50 px-2 py-2 border rounded-lg drop-shadow-md'>
                <form
                    className='bg-white flex border rounded-lg'
                    onSubmit={(event) => fetchTenant(event)}
                >
                    <input
                        id="tenantNameInput"
                        type="text"
                        className='rounded-lg outline-none py-1 px-2'
                        placeholder="Enter tenant id..."
                        defaultValue={tenantName}
                    />
                    <button
                        type="button"
                        className='pr-2'
                        onClick={() => Inertia.visit(url)}
                    >
                        <IoIosClose className='text-slate-400 text-2xl'/>
                    </button>
                </form>

                {
                    (tenantName && !organization) && (
                        <h2 className='ml-6 text-gray-500'>Loading...</h2>
                    )
                }
                {
                    (tenant === "not found") && (
                        <h2 className='ml-6 text-gray-500'>
                            No tenant with name "<span className='italic'>{tenantName}</span>" found.
                        </h2>
                    )
                }
            </div>

            {
                (tenant && organization) && <div className='flex items-start justify-around bg-slate-50 px-2 py-2 border rounded-lg drop-shadow-md'>
                    <div className='flex-col p-4'>
                        <h2 className='font-bold'>Tenant info: </h2>
                        <div className='flex mt-4'>
                            <DisplayJSON
                                id="tenant_info"
                                jsonData={tenant}
                                onKeyClick={(key, value) => {
                                    navigator.clipboard.writeText(`${key}: ${value}`);
                                }}
                            />
                        </div>
                    </div>
                    <div className='flex-col p-4'>
                        <h2 className='font-bold'>Organization info: </h2>
                        <div className='flex mt-4'>
                            <DisplayJSON
                                id="organization"
                                jsonData={organization}
                                onKeyClick={(key, value) => {
                                    navigator.clipboard.writeText(`${key}: ${value}`);
                                }}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
