import React, { useState, useEffect } from "react";
import { IoIosClose } from 'react-icons/io';
import { deepParseJson } from "deep-parse-json";

export const LogDataDrawer = ({ id, logData, setSelectedLog }) => {
    const [ curSelected, setCurSelected ] = useState({})

    const toggleLogDataDrawer = (newOpenState) => {
        const drawerContainer = document.getElementById(`${id}_container`);
        const drawerContent = document.getElementById(`${id}_content`);
        const logTableContainer = document.getElementById(`logTableContainer`);

        const drawerWidth = 30;

        drawerContent.style.width = newOpenState? "100%": "";
        drawerContent.style.padding = newOpenState? "1rem 1rem": "";
        drawerContainer.style.width = newOpenState? `${drawerWidth}%`: "";
        logTableContainer.style.width = newOpenState? `${100 - drawerWidth}%`: "";
    }

        const closeDrawer = () => {
        toggleLogDataDrawer(false)
        setCurSelected({})
        setSelectedLog({})
    }

    function syntaxHighlight(json) {
        if (typeof json != 'string') {
            json = JSON.stringify(deepParseJson(json), null, 2)
        }

        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        const result = json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            let cls = 'text-purple-600 font-bold';

            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'text-red-600 font-thin italic'; // key
                    match = match.replace(/"/g, "");
                }
                else {
                    cls = 'text-green-600 font-medium'; // string
                }
            }
            else if (/true|false/.test(match)) {
                cls = 'text-blue-600 font-bold'; // bool
            }
            else if (/null/.test(match)) {
                cls = 'text-gray-600 font-bold'; //null
                match = "NULL"
            }

            return `<span class="${cls}">${match}</span>`
        });

        const pre = document.getElementById("json_display")
        pre.innerHTML = result;
    }

    useEffect(() => {
        function changeSelectedLog() {
            const logSelected = !!logData.id
            const isDifferentLog = logData.id !== curSelected.id

            toggleLogDataDrawer(logSelected && isDifferentLog)
            setCurSelected(deepParseJson(logData))

            if(logData.data) {
                syntaxHighlight(logData.data);
            }
        }
        changeSelectedLog();
    }, [logData]);

    return (
        <div
            id={`${id}_container`}
            className='fixed top-[0px] right-0 bottom-0 transition-all delay-300 flex justify-end drop-shadow-lg overflow-hidden'
        >

            <div
                id={`${id}_content`}
                className="flex flex-col transition-all w-0 h-full bg-slate-100 overflow-auto break-words"
            >
                <pre className="absolute top-0 text-xs -ml-3 text-gray-400"><i>[esc to close]</i></pre>
                <pre id="json_display"></pre>
            </div>

        </div>
    )
}
